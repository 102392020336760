import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../Styles/technologies.css";
import Bounce from "react-reveal/Bounce";
import RoutingNavbar from "./RoutingNavbar";
import Footer from "./footer";
import product1 from "./../images/productengineeringtools.png";
import { Row, Col, Card, Container } from "react-bootstrap";
import tech from "../images/tech.jpg";
import develop from "../images/develop.PNG";
class Technologies extends React.Component {
  render() {
    return (
      <div>
        <div>
          {" "}
          <RoutingNavbar />
        </div>
        <br />
        <br />{" "}
        {/* <div className="career-top">
            <p className="career-tophead" align="center">
              We do not use technologies we live with technologies
            </p>
            
          </div> */}
        <br />
        <br />
        <Row>
          <Col
            style={{ marginLeft: "7%", paddingTop: "10%", color: "#202b5d" }}
          >
            {" "}
            <div>
              {" "}
              <p className="career-tophead" align="left">
                We Do Not Use Technologies <br /> We Live With Technologies
              </p>{" "}
            </div>
          </Col>

          <Col>
            <img src={tech} width="90%" height="90%" />
          </Col>
          <br />
          <br />
        </Row>
        <br />
        <div class="centered" style={{ marginLeft: "4%" }} id="technologies">
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "#202B5D",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {" "}
              Data engineering tools we work with{" "}
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Appache Spark </div>
            <div>Cloudera</div>
            <div>kafka</div>
            <div>Spark SQL</div>
            <div>Spark Streaming</div>
            <div>mongoDB</div>
            <div>amazon web services</div>
            <div>Google Cloud</div>
            <div>Hive</div>
            <div>hadoop</div>
            <div>presto</div>
            <div>Appache Storm</div>
            <div>Cloudbase</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "#202B5D",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {" "}
              Data Science and Machine Learning tools we work with{" "}
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Appache Spark ML</div>
            <div>TensorFlow</div>
            <div>Caffe</div>
            <div>theano</div>
            <div>
              H<sub>2</sub>O.ai
            </div>
            <div>amazon webservices</div>
            <div>Microsoft Azure</div>
            <div>Google Cloud</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "#202B5D",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {" "}
              BI tools we work with{" "}
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Pentaho</div>
            <div>Power BI</div>
            <div>Qlik</div>
            <div>+ableau</div>
            <div>metatron Discovery</div>
            <div>microStrategy</div>
            <div>looker</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "#202B5D",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {" "}
              Blockchain tools we work with{" "}
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Ethereum</div>
            <div>Smart Contracts</div>
            <div>Quorum</div>
            <div>Corda</div>
            <div>Stellar</div>
            <div>Hyperledger</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "#202B5D",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {" "}
              Mobility tools we work with{" "}
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Android</div>
            <div>iOS</div>
            <div>Xamarin</div>
            <div>Ionic</div>
            <div>React Native</div>
            <div>Flutter</div>
            <div>HTML5</div>
            <div>Cordova</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "#202B5D",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {" "}
              Open Source tools we work with{" "}
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Java</div>
            <div>Python</div>
            <div>PHP</div>
            <div>Angular</div>
            <div>ReactJS</div>
            <div>NodeJS</div>
            <div>MEAN</div>
            <div>MERN</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "#202B5D",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {" "}
              Cloud tools we work with{" "}
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>AWS</div>
            <div>Azure</div>
            <div>Google CLoud</div>
          </div>
          <br />
          <br />
          <br />
          <Bounce bottom>
            <h1
              className="tech-heading"
              style={{
                color: "#202B5D",
                fontWeight: "500",
                textAlign: "left",
              }}
            >
              {" "}
              UI/UX tools we work with{" "}
            </h1>
            <br />
          </Bounce>
          <div class="flex-container">
            <div>Adobe XD</div>
            <div>Invision</div>
            <div>Storybook</div>
            <div>Sketch</div>
            <div>UXPin</div>
            <div>Figma</div>
            <div>Whimsical</div>
            <div>OmniGraffle</div>
            <div>Balsamiq</div>
            <div>JustInMind</div>
          </div>
          <br />
          <br />
          <br />
        </div>
        <h1
          className="tech-heading"
          style={{
            color: "#202B5D",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Our Development Process
        </h1>
        <br />
        <br />
        <div
          className="industries-container"
          style={{ justifyContent: "center", display: "flex" }}
        >
          <img width="65%" height="auto" src={develop} />
        </div>
        <br />
        <br />
        <h4 className="h-heading" align="center">
          There is no alternative to digital transformation
        </h4>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <a href="/#contact">
            <div className="getdemo-center" align="center">
              Contact Us
            </div>
          </a>
        </div>
        <br />
        <br />
        <br />
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Technologies;
