import React from "react";
import { Col, Row, Container } from "reactstrap";
import "./../Styles/capabilities.css";
import Bounce from "react-reveal/Bounce";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Tabs, { Tab } from "react-best-tabs";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-best-tabs/dist/index.css";
import { Card } from "react-bootstrap";

class Capabilities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      option: "product",
    };
  }

  handleresize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleresize);
  }

  componentWillUnmount() {
    window.addEventListener("resize", this.handleresize);
  }

  render() {
    const { windowWidth } = this.state;
    var carousel = {
      dots: true,
      infinite: true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
    };
    return (
      <div>
        <div>
          <div>
            <Bounce bottom>
              <h1
                className="h-heading"
                style={{
                  color: "#202b5d",
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                {" "}
                Capabilities{" "}
              </h1>
              <br />
            </Bounce>
            <div>
              {windowWidth >= "600" ? (
                <Tabs
                  activeTab="1"
                  className="tab-text-color"
                  ulClassName=""
                  activityClassName="bg-success"
                  onClick={(event, tab) => console.log(event, tab)}
                >
                  <Tab title="Product Engineering" className="mr-3">
                    <div className="mt-3">
                      <div className="capability-container">
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Planning (Idea and Concept){this.state.cwidth}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                At the starting point of product development,
                                the main aim is to outline the general idea of a
                                product and to form the initial concept.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Design</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                During the Design Process, Product Engineer
                                plays an integral part in creating the most
                                user-friendly and cost-effective product design
                                possible.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Development</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                After the design is finally done, the
                                development phase begins. When creating a
                                software product, it should be divided into
                                features, with the specifications and the
                                usability test for each feature
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Testing</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                After the development stage, the product should
                                go through testing to detect functionality
                                problems and to evaluate its quality.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Launch and Maintenance
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Product Engineer is concerned about the user's
                                feedback on the product in order to find areas
                                for improvement.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab title="Cloud Services" className="mr-3">
                    <div className="mt-3">
                      <div className="capability-container">
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Consult</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Helping with the cloud roadmap design while
                                re-imagining workflows and processes to adapt to
                                future state in cloud.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Build</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Including foundation services & migration,
                                modernization, data lake design/build, workplace
                                services through a compostable architecture
                                design.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Operate</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Enabled through BOT-first integrated delivery
                                model, experience-focused platform-led
                                operations & economics 360
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Data</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Services for data modernization and
                                monetization, hyper personalization,
                                intelligence & edge analytics cloud transform:
                                XaaS model for cloud native development,
                                Cognitive Customer Service (CCaaS), Business
                                360° (advance visualization & insights),
                                industry
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Security</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Driving security contextualization around
                                DevSecOps services, DR/BCP services & GRC/Audit
                                services
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab title="Data and Intelligence" className="mr-3">
                    <div className="mt-3">
                      <div className="capability-container">
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Data Modernization
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                To build a futuristic data supply chain, we
                                transform your data ecosystem from silos to a
                                unified data marketplace.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Data Operations and Management
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Ensure availability of quality data supported by
                                data governance and data harmonization to users
                                across enterprise.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Insights and Action{" "}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                We charge your business by deriving data
                                insights and help create value through analytics
                                and AI solutions.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Internet of Things (IoT)
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Harness IoT for experience transformation,
                                connected operations, smart manufacturing and
                                new business models with sensor data and
                                analytics.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab
                    title="Enterprise IT Transformation and Automation"
                    className="mr-3"
                  >
                    <div className="mt-3">
                      <div className="capability-container">
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Hyper-automated Infrastructure and Applications
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Enterprise IT infrastructure, applications &
                                end-user support in a hyper-automated unified IT
                                operating model.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Digital Security
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Enterprise cyber security that provides cyber
                                protection, cyber defense, IAM, and GRC
                                services.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Application Portfolio Transformation
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Assess your collection of enterprise IT
                                applications, and rationalize, modernize and
                                automate them.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Quality Engineering
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Drive speed and quality with automated quality
                                engineering and continuous testing.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Accelerated DevSecOps
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Embrace DevSecOps across your entire CI/CD
                                pipeline with a platform-led approach
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Enterprise Application Services
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Transform and automate your SAP environment and
                                evolutionize your SAP workforce.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Product IT Transformation
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Drive evolutionary business-aligned and
                                product-aligned enterprise IT support and
                                application development.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  </Tab>
                </Tabs>
              ) : (
                <div>
                  <div
                    className=" d-flex justify-content-center"
                    style={{ margin: "10%", marginTop: "-5%" }}
                  >
                    <select
                      className="btn btn-primary dropdown-toggle"
                      onChange={(e) =>
                        this.setState({ option: e.target.value })
                      }
                      value={this.state.option}
                    >
                      <option
                        class="dropdown-menu dropdown-item"
                        value="product"
                      >
                        Product Engineering
                      </option>
                      <option class="dropdown-menu dropdown-item" value="cloud">
                        Cloud Services
                      </option>
                      <option class="dropdown-menu dropdown-item" value="DI">
                        Data and Intelligence
                      </option>
                      <option
                        class="dropdown-menu dropdown-item"
                        value="enterprise"
                      >
                        Enterprise IT Transformation and Automation
                      </option>
                    </select>
                  </div>
                  {this.state.option === "product" && (
                    <div
                      className="d-flex flex-wrap justify-content-center"
                      style={{ marginTop: "4%" }}
                    >
                      <div className="capability-container">
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Planning (Idea and Concept){this.state.cwidth}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                At the starting point of product development,
                                the main aim is to outline the general idea of a
                                product and to form the initial concept.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Design</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                During the Design Process, Product Engineer
                                plays an integral part in creating the most
                                user-friendly and cost-effective product design
                                possible.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Development</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                After the design is finally done, the
                                development phase begins. When creating a
                                software product, it should be divided into
                                features, with the specifications and the
                                usability test for each feature
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Testing</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                After the development stage, the product should
                                go through testing to detect functionality
                                problems and to evaluate its quality.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Launch and Maintenance
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Product Engineer is concerned about the user's
                                feedback on the product in order to find areas
                                for improvement.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.option === "cloud" && (
                    <div
                      className="d-flex flex-wrap justify-content-center"
                      style={{ marginTop: "4%" }}
                    >
                      <div className="capability-container">
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Consult</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Helping with the cloud roadmap design while
                                re-imagining workflows and processes to adapt to
                                future state in cloud.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Build</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Including foundation services & migration,
                                modernization, data lake design/build, workplace
                                services through a compostable architecture
                                design.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Operate</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Enabled through BOT-first integrated delivery
                                model, experience-focused platform-led
                                operations & economics 360
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Data</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Services for data modernization and
                                monetization, hyper personalization,
                                intelligence & edge analytics cloud transform:
                                XaaS model for cloud native development,
                                Cognitive Customer Service (CCaaS), Business
                                360° (advance visualization & insights),
                                industry
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">Cloud Security</p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Driving security contextualization around
                                DevSecOps services, DR/BCP services & GRC/Audit
                                services
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.option === "DI" && (
                    <div
                      className="d-flex flex-wrap justify-content-center"
                      style={{ marginTop: "4%" }}
                    >
                      <div className="capability-container">
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Data Modernization
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                To build a futuristic data supply chain, we
                                transform your data ecosystem from silos to a
                                unified data marketplace.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Data Operations and Management
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Ensure availability of quality data supported by
                                data governance and data harmonization to users
                                across enterprise.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Insights and Action{" "}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                We charge your business by deriving data
                                insights and help create value through analytics
                                and AI solutions.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Internet of Things (IoT)
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Harness IoT for experience transformation,
                                connected operations, smart manufacturing and
                                new business models with sensor data and
                                analytics.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )}
                  {this.state.option === "enterprise" && (
                    <div
                      className="d-flex flex-wrap justify-content-center"
                      style={{ marginTop: "4%" }}
                    >
                      <div className="capability-container">
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Hyper-automated Infrastructure and Applications
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Enterprise IT infrastructure, applications &
                                end-user support in a hyper-automated unified IT
                                operating model.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Digital Security
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Enterprise cyber security that provides cyber
                                protection, cyber defense, IAM, and GRC
                                services.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Application Portfolio Transformation
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Assess your collection of enterprise IT
                                applications, and rationalize, modernize and
                                automate them.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Quality Engineering
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Drive speed and quality with automated quality
                                engineering and continuous testing.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Accelerated DevSecOps
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Embrace DevSecOps across your entire CI/CD
                                pipeline with a platform-led approach
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Enterprise Application Services
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Transform and automate your SAP environment and
                                evolutionize your SAP workforce.
                              </p>
                            </Col>
                          </Row>
                        </div>
                        <div className="capability-1">
                          <Row>
                            <Col>
                              <p className="capability-head">
                                Product IT Transformation
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <p className="capability-content ">
                                Drive evolutionary business-aligned and
                                product-aligned enterprise IT support and
                                application development.
                              </p>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* <Row style={{ margin: '0px' }}>
                  <Col md='4' lg='3' sm='6' xs='6'>
                    <img src={iOS} className="ios" width='150px' height='150px' />
                    <p className="iostext">iOS</p>
                  </Col>
                  <Col md='4' lg='3' sm='6' xs='6' >
                    <img src={Android} className="ios" width='150px' height='150px' />
                    <p className="iostext">Android</p>
                  </Col>
                  <Col md='4' lg='3' sm='6' xs='6' >
                    <img src={webUI} className="ios" width='150px' height='150px' />
                    <p className="iostext">Web UI</p>
                  </Col>
                  <Col md='4' lg='3' sm='6' xs='6'>
                    <img src={Backend} className="ios" width='150px' height='150px' />
                    <p className="iostext">Backend</p>
                  </Col>
                  <Col md='4' lg='3' sm='6' xs='6'>
                    <img src={AIorML} className="ios" width='150px' height='150px' />
                    <p className="iostext">AI/ML</p>
                  </Col>
                  <Col md='4' lg='3' sm='6' xs='6'>
                    <img src={BlockChain} className="ios" width='150px' height='150px' />
                    <p className="iostext">BlockChain</p>
                  </Col>
                  <Col md='4' lg='3' sm='6' xs='6'>
                    <img src={IoT} className="ios" width='150px' height='150px' />
                    <p className="iostext">IoT</p>
                  </Col>
                  <Col md='4' lg='3' sm='6' xs='6'>
                    <img src={ARorVR} className="ios" width='150px' height='150px' />
                    <p className="iostext">AR/VR</p>
                  </Col>
                </Row> */}
            </div>
            {/* </Bounce> */}
          </div>
        </div>
      </div>
    );
  }
}
export default Capabilities;
