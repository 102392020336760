import React, { Component } from 'react'
import { Row,Col } from 'react-bootstrap'
import { Label } from 'reactstrap'
import {IoIosCloudDone} from 'react-icons/io'
import {AiOutlineFolder} from 'react-icons/ai'
import Footer from '../footer'
export class Applyjobs extends Component {
    constructor(props){
        super(props);
        this.state = {
            selectedfile:""
        }
        this.fileInput = React.createRef();
    }

    componentDidMount(){
      window.scrollTo(0, 0)
    }
    imageHandler = (e) => {
        this.setState({selectedfile:e.target.files[0]})
    }
  render() {
    return (
      <div>
          <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Tell us about you</p>
     </div>
          <Row style={{margin:'0px'}}>
              <Col md="2"></Col>
              <Col md="8">
              <div className='apply-card'>
               <Row style={{margin:'0px'}}>
                 <Col md="1"></Col>
                 <Col md="5" xs={12} sm={12}>
                 <Label className='h-label'>Name</Label><br/>
                 <input placeholder='Enter your full name' className='h-input'></input><br/>
                 <Label className='h-label'>Email address</Label><br/>
                  <input placeholder='Enter your email' className='h-input'></input><br/>
                  <Label className='h-label'>Phone Number</Label><br/>
                  <input placeholder='Enter your phone number' className='h-input'></input><br/>
                 </Col>
                 <Col md="5">
                 <p className='drop-head'>Drop your resume</p>
                     <div className='file-holder'>
                      
                      {this.state.selectedfile==="" ? <AiOutlineFolder className='upload-icon'/> : <IoIosCloudDone className="upload-icon" style={{color:"green"}}/>}
                      <input type="file" name="file-upload" id="input" required accept="/*" ref={this.fileInput} onChange={this.imageHandler} /><br />
                    {this.state.selectedfile==="" ? <label  name="upload" htmlFor="input" className="file-upload">Click&nbsp;to&nbsp;browse</label> : <p className="filename" align="center">{this.state.selectedfile.name}</p>}
                      </div>
                 </Col>
                 <Col md="1"></Col>
               </Row><br/>
               <Row style={{margin:'0px'}}>
           <Col md="4"></Col>
           <Col md="5">
             <p className='finalapply-btn'>Apply</p>
           </Col>
           <Col md="3"></Col>
          </Row>
              </div>
              </Col>
              <Col md="2"></Col>
          </Row><br/><br/><br/>
          <div>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Applyjobs


