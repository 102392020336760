import React from "react";
import {TiShoppingCart} from 'react-icons/ti';
import {AiOutlineAppstoreAdd} from 'react-icons/ai';
import {MdProductionQuantityLimits} from 'react-icons/md';
import {BsGraphUp} from 'react-icons/bs'

import {Col,Row} from 'reactstrap'
import shopify from '../images/shopify.jpg'

import '../Styles/shopify.css'

function Shopify()
{
    return(
        <div  className="top" style={{marginTop:"-3%"}}>
<div className="top-container" style={{backgroundColor:"#007ba7",color:"#F5FEFD"}} >
    {/* <section>

        <div class="curve" />
    </section> */}
    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#000099" fill-opacity="1" d="M0,256L40,245.3C80,235,160,213,240,181.3C320,149,400,107,480,90.7C560,75,640,85,720,117.3C800,149,880,203,960,234.7C1040,267,1120,277,1200,277.3C1280,277,1360,267,1400,261.3L1440,256L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path></svg>
    */}
    <h1 className="heading" style={{color:"white",lineHeight:"0.5",padding:"50px 0px 30px",fontFamily:"inherit",fontSize:"xx-large",marginTop:"3%"}} >  <TiShoppingCart  size={45} style={{color:"#F5FEFD"}}/>Travail Stores</h1>
        <h1 className="heading" style={{fontFamily: "cursive",fontSize:"normal",fontWeight:"500%"}}>Build your own ecommerce application in 3 easy steps</h1>
        <h4 className="heading" style={{fontFamily:"ShopifySans, Helvetica, Arial, sans-serif",fontWeight:"unset",fontSize:"x-large",marginTop:"3%"}}>Travail Stores is trusted by businesses worldwide</h4>
<Row>
    
        <Row>
        <Col className="mt-4  d-flex"   >
<input className="control" type="text" placeholder="Enter your Email address" label="Email address"  style={{height:"auto"}} />
</Col>
<Col className="mt-4"  >
<button  className="bt">Start free trial</button>
</Col>
</Row>

</Row>
 <br></br>
  <h4 className="heading" style={{fontSize:"15px"}}>By entering your email, you agree to receive marketing emails from Travail Stores.</h4>

 <br></br>


<div>
<img src={shopify} alt="global" width='40%' height='40%' className="bag-image" style={{marginLeft:"24%",height:"50%",width:"50%"}} />

</div>
</div>
<Row>
    
<div className="container" style={{padding:"70px 12px 90px"}}>
<Col>
    <div className="item"  style={{marginLeft:"10%"}}>
    <AiOutlineAppstoreAdd size={40} style={{color:"#007fbf"}} />
<h4 style={{fontFamily: "ShopifySans, Helvetica, Arial, sans-serif",fontSize:"large"
}}>Choose & customise a theme</h4>

<p>Customize the design of your shop from over thousands of themes. No design experience or programming skills required.</p>
</div>
</Col>
<Col>
<div className="item" style={{marginLeft:"10%"}}>
<MdProductionQuantityLimits size={40} style={{color:"#007fbf"}} />
<h4 style={{fontFamily: "ShopifySans, Helvetica, Arial, sans-serif",fontSize:"large"
}}>Add products</h4>

<p>Add your products to the shop. List your eye-catching products with the best photos, price and descriptions.</p>
</div>
</Col>
<Col>
<div className="item" style={{marginLeft:"10%"}}>
<BsGraphUp size={40} style={{color:"#007fbf"}} />
<h4 style={{fontFamily: "ShopifySans, Helvetica, Arial, sans-serif",fontSize:"large"
}}>Start selling</h4>

<p>Set up payments and shipping, and start selling.</p>
</div>
</Col>
<br></br>
</div>
</Row>

<div className="heading1">
<label style={{fontSize:"x-large",fontFamily: "ShopifySans, Helvetica, Arial, sans-serif"}}>“Travail Stores is the perfect solution for my ecommerce application, </label>
<br></br>
<label style={{fontSize:"x-large",fontFamily: "ShopifySans, Helvetica, Arial, sans-serif"}}>which both easy to build and easy to maintain.”</label>
<br></br>
<br></br><br></br>
<label style={{fontSize:"0.975em",fontFamily: "ShopifySans, Helvetica, Arial, sans-serif",
    textTransform: "uppercase",
    letterSpacing: "0.04375em",fontWeight:"bold",    color: "#42474c"}} > SHRI SRI RAM, FOUNDER OF ORGANIC CARE CHENNAI</label>
<br></br>
<button  style={{backgroundColor:"#0077be",margin:"45px 217px 53px 30%",marginRight:"20%",marginLeft:"20%"}} >Start free trial</button>
<br></br>
<Col style={{}}>
<label style={{textDecoration: "none",fontSize:"larger",
    cursor: "pointer",
    color: "#0077be",fontFamily:"Times New Roman"}}>Terms of Service</label>
&nbsp;&nbsp;&nbsp;&nbsp;
<label style={{textDecoration: "none",
    cursor: "pointer",
    color: "#0077be",fontFamily:"Times New Roman",fontSize:"larger"}}>Privacy Policy</label>
</Col>
</div>


</div>

    )


}

export default Shopify;