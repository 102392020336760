import React from "react";
import Service from "./Components/Services";
import Quote from "./Components/Quote.js";
import Client from "./Components/clients";
import Footer from "./Components/footer";
import Contact from "./Components/contact";
import SimpleForm from "./Components/SimpleForm";
import Bounce from "react-reveal/Bounce";
import CareerCards from "./Components/Career/CareerCards";
import { BrowserRouter, Switch, Router, Route } from "react-router-dom";
import CareerDetailsBackend from "./Components/Career/CareerDetailsBackend";
import CareerDetailsFrontend from "./Components/Career/CareerDetailsFrontend";
import CareerDetailsTesting from "./Components/Career/CareerDetailsTesting";
import CareerDetailsDataScientist from "./Components/Career/CareerDetailsDataScientist";
import CustomerSuccessRole from "./Components/Career/CustomerSuccessRole";
import DigitalMarketing from "./Components/Career/DigitalMarketing";
import CareerDetailsHR from "./Components/Career/CareerDetailsHR";
import SalesDirector from "./Components/Career/SalesDirector";
import Shopify from "./Components/shopify";
import RD from "./Components/R&D";
import IndustriesWeServe from "./Components/IndustriesWeServe";
import NewServices from "./Components/NewService";
import Technologies from "./Components/Technologies";
import Capabilities from "./Components/Capabilities";
import Blog1 from "./Components/ResearchBlogs/Blog1";
import Blog2 from "./Components/ResearchBlogs/Blog2";
import Blog3 from "./Components/ResearchBlogs/Blog3";
import Applyjobs from "./Components/Career/Applyjobs";
import Academy from "./Components/Academy";
import Product from "./Components/Products.js";

class Layout extends React.Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              <div>
                <Quote />
              </div>

              <div>
                <Service />
              </div>
              <br />
              <br />
              <div>
                <Capabilities />
              </div>

              {/* <div>
                <RowCards />
              </div> */}

              {/* <div>
                <Feature />
              </div> */}
              <div>
                <IndustriesWeServe />
              </div>

              {/* <br />
              <br /> */}
              <div>
                <br />
                <br />
                <br />
                <div id="testimonials" className="App">
                  <Bounce bottom>
                    <h1
                      className="h-heading"
                      style={{
                        color: "#202B5D",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      Here's what our customers saying about us!{" "}
                    </h1>
                    <br />
                  </Bounce>

                  <Client />
                </div>
              </div>

              <div>
                <Contact />
              </div>
              <div>
                <Footer />
              </div>
              <SimpleForm></SimpleForm>
            </Route>
            <Route exact path="/service" component={NewServices}></Route>
            <Route exact path="/RD" component={RD}></Route>
            <Route exact path="/Products" component={Product}></Route>
            <Route exact path="/Career" component={CareerCards}></Route>
            <Route exact path="/apply-jobs" component={Applyjobs}></Route>
            <Route exact path="/technologies" component={Technologies}></Route>
            <Route
              exact
              path="/Career/CareerDetailsBackend"
              component={CareerDetailsBackend}
            ></Route>
            <Route
              exact
              path="/Career/CareerDetailsFrontend"
              component={CareerDetailsFrontend}
            ></Route>
            <Route
              exact
              path="/Career/CareerDetailsTesting"
              component={CareerDetailsTesting}
            ></Route>
            <Route
              exact
              path="/Career/CareerDetailsDataScientist"
              component={CareerDetailsDataScientist}
            ></Route>
            <Route
              exact
              path="/Career/CareerDetailsCustomerSuccess"
              component={CustomerSuccessRole}
            ></Route>
            <Route
              exact
              path="/Career/CareerDetailsDigitalMarketing"
              component={DigitalMarketing}
            ></Route>
            <Route
              exact
              path="/Career/CareerDetailsHR"
              component={CareerDetailsHR}
            ></Route>
            <Route
              exact
              path="/Career/CareerDetailsSalesDirector"
              component={SalesDirector}
            ></Route>
            <Route exact path="/academy" component={Academy}></Route>
            <Route exact path="/E-Commerce" component={Shopify}></Route>
            <Route
              exact
              path="/RD/Machine-Learning-Blog"
              component={Blog1}
            ></Route>{" "}
            <Route exact path="/RD/Deep-Tech-Blog" component={Blog2}></Route>{" "}
            <Route
              exact
              path="/RD/Artificial-Intelligence-Blog"
              component={Blog3}
            ></Route>
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
export default Layout;
