import React from "react";
import ico1 from "../images/ico01.svg";
import ico2 from "../images/ico02.svg";
import ico3 from "../images/ico03.svg";
import ico4 from "../images/ico04.svg";
import new1 from "../images/new1.png";
import new2 from "../images/new2.png";
import new3 from "../images/new3.png";
import new4 from "../images/new4.jpg";
import "../Styles/service.css";
import "../Styles/card.css";
import { Col, Row, Container } from "reactstrap";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import Flip from "react-reveal/Flip";
class Service extends React.Component {
  render() {
    return (
      <div>
        <div className="experience-section">
          <Fade right>
            <div className="col lg-12">
              <div className="firstcon">
                <h2 className="title--h2">
                  Our
                  <span className="special-text"> Proven Track </span>
                  Record
                </h2>
                <p className="content12">
                  Get secret sauce for your success from expertise. Let’s work
                  together to lays the foundation for future business
                  intelligence.
                </p>
              </div>
            </div>
          </Fade>

          {/* <div className="points"> */}
          <Bounce bottom>
            <div className="points">
              <Row>
                <div className="col-md-3 mb-4">
                  <div className="point">
                    <div className="icon">
                      <img src={new1} className="img-fluid" />
                    </div>
                    <div className="details">
                      <div className="counting">
                        <h2 className="title--h1" id="counter1">
                          50<span className="star">+</span>
                        </h2>

                        <p>Project Completed</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="point" id="start">
                    <div className="icon">
                      <img src={new2} className="img-fluid" />
                    </div>
                    <div className="details">
                      <div className="counting">
                        <h2 className="title--h1" id="counter1">
                          40<span className="star">+</span>
                        </h2>

                        <p>Happy Clients</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="point" id="start">
                    <div className="icon">
                      <img src={new3} className="img-fluid" />
                    </div>
                    <div className="details">
                      <div className="counting">
                        <h2 className="title--h1" id="counter1">
                          100<span className="star">%</span>
                        </h2>

                        <p>On time Delivery</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 mb-4">
                  <div className="point" id="start">
                    <div className="icon">
                      <img src={new4} className="img-fluid" />
                    </div>
                    <div className="details">
                      <div className="counting">
                        <h2 className="title--h1" id="counter1">
                          5<span className="star">*</span>
                        </h2>

                        <p>Client Rating</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </div>
          </Bounce>
          {/* </div> */}
        </div>
        <br />
        <div className="header">
          <Bounce bottom>
            <Row style={{ margin: "0px" }}>
              <Col lg="2" xl="2" md="2" sm="0" xs="0" />
              <Col>
                <h2
                  className="quote"
                  style={{
                    color: "#202b5d",
                    textAlign: "center",
                    paddingLeft: "5%",
                  }}
                >
                  Online B2B impressive solutions to increase your revenue
                </h2>
                <h4 className="sub-desc">
                  Leading work Management solution to gain visiblity.
                </h4>
              </Col>
              <Col lg="2" xl="2" md="2" />
            </Row>
          </Bounce>
        </div>

        <div>
          <div className="card-container">
            <div className="row">
              <Flip top>
                <div className="card">
                  <div className="card-head">
                    <span className="pink">
                      <img src={ico1} alt="icon" width="40" height="40" />
                    </span>
                    <p className="q-heading">Product Development</p>
                  </div>
                  <div className="card-body">
                    <p style={{ fontSize: "18px" }}>
                      This includes identifying a market need, researching the
                      competitive landscape, conceptualizing a solution,
                      developing a product roadmap, building a minimum viable
                      product.
                    </p>
                  </div>
                </div>
              </Flip>

              <Flip top>
                <div className="card">
                  <div className="card-head">
                    <span className="blue">
                      <img src={ico2} alt="icon" width="40" height="40" />
                    </span>
                    <p className="q-heading">Market your Business</p>
                  </div>
                  <div className="card-body">
                    <p style={{ fontSize: "18px" }}>
                      Take the guesswork out of marketing with built-in tools
                      that help you create, execute, and analyze digital
                      marketing campaigns.
                    </p>
                  </div>
                </div>
              </Flip>

              <Flip top>
                <div className="card">
                  <div className="card-head">
                    <span className="orange">
                      <img src={ico3} alt="icon" width="40" height="40" />
                    </span>
                    <p className="q-heading">Manage Everything</p>
                  </div>
                  <div className="card-body">
                    <p style={{ fontSize: "18px" }}>
                      Gain the insights you need to grow—use a single dashboard
                      to manage orders, shipping, and payments anywhere you go.
                    </p>
                  </div>
                </div>
              </Flip>

              <Flip top>
                <div className="card">
                  <div className="card-head">
                    <span className="violet">
                      <img src={ico4} alt="icon" width="40" height="40" />
                    </span>
                    <p className="q-heading">Cloud Storage</p>
                  </div>
                  <div className="card-body">
                    <p style={{ fontSize: "18px" }}>
                      It controls how all data is stored and retrieved & placed
                      in a storage data pool secured backup mode Protocal tools
                      man. Protocal tools man.
                    </p>
                  </div>
                </div>
              </Flip>
            </div>
          </div>
          {/* <Fade right>
            <a
              data-text="See All Features"
              className="see"
              style={{ alignSelf: "center" }}
              href="#features"
            >
              See All Features
            </a>
          </Fade> */}
        </div>

        <div id="why">
          <br />
          <br />

          <div className="box">
            <Container>
              <div className="facts">
                <Bounce top>
                  <h1 style={{ color: "#fff" }}>Some Real Facts</h1>
                  <p
                    style={{
                      color: "#9da8bc",
                      fontSize: "20px",
                      marginBottom: "5%",
                    }}
                  >
                    We help you increase sales by improving your digital
                    presence.
                  </p>
                </Bounce>
                <Fade top>
                  <div>
                    <Row style={{ margin: "0px" }}>
                      <Col md="4" sl="6" lg="4">
                        <div className="p1">
                          <p style={{ color: "#00ffff" }}>250+</p>
                          <p
                            style={{
                              color: "#9da8bc",
                              fontSize: "20px",
                              marginBottom: "7%",
                            }}
                          >
                            Focus on Online Business & vision.
                          </p>
                        </div>
                      </Col>
                      <Col md="4" sl="6" lg="4">
                        <div className="p2">
                          <p style={{ color: "aqua" }}>140M</p>
                          <p
                            style={{
                              color: "#9da8bc",
                              fontSize: "20px",
                              marginBottom: "7%",
                            }}
                          >
                            Improved thier Business revenue by Analytics
                          </p>
                        </div>
                      </Col>
                      <Col md="4" sl="6" lg="4">
                        <div className="p3">
                          <p style={{ color: "aqua" }}>35%</p>
                          <p
                            style={{
                              color: "#9da8bc",
                              fontSize: "20px",
                              marginBottom: "7%",
                            }}
                          >
                            Yearly average Business Growth{" "}
                          </p>
                        </div>
                      </Col>
                      <span className="color" />
                    </Row>
                  </div>
                </Fade>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
export default Service;
