import React, { Component } from "react";
import RoutingNavbar from "./RoutingNavbar";
import "../Styles/academy.css";
import { Row, Col, Card, Container } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import img from "../assets/study.jpg";
import LightSpeed from "react-reveal/LightSpeed";
import iot from "../images/iot1.svg";
import blockchain1 from "../images/blockchain1.svg";
import ai1 from "../images/AI1.svg";
import ml1 from "../images/ML1.svg";
import chatbot from "../images/Chatbots.svg";
import vr1 from "../images/vr1.svg";
import ar1 from "../images/ar1.svg";
import { Icon } from "@iconify/react";
import ace1 from "../assets/ace1.jpg";
import grp from "../assets/grp.jpg";
import question from "../assets/question1.jpg";
import numone from "../assets/num1.png";
import numtwo from "../assets/num2.png";
import numthree from "../assets/num3.png";
import company from "../assets/company1.png";
import message from "../assets/chat.png";
import group from "../assets/group.png";
import single from "../assets/study1.png";
import Footer from "./footer";
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
export default class Academy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qfa1: false,
      qfa2: false,
      qfa3: false,
      qfa4: false,
      qfa5: false,
      ques5: false,
      ques4: false,
      ques3: false,
      ques2: false,
      ques1: false,
    };
  }
  toggle = () => {
    this.setState({ qfa1: !this.state.qfa1 });
  };
  toggle1 = () => {
    this.setState({ qfa2: !this.state.qfa2 });
  };
  toggle2 = () => {
    this.setState({ qfa3: !this.state.qfa3 });
  };
  toggle3 = () => {
    this.setState({ qfa4: !this.state.qfa4 });
  };
  toggle4 = () => {
    this.setState({ qfa5: !this.state.qfa5 });
  };
  render() {
    return (
      <div>
        <div>
          {" "}
          <RoutingNavbar />
        </div>
        <br />
        <br />{" "}
        <div className="content_acc">
          <Container>
            <Row
              style={{
                margin: "0px",
              }}
            >
              <Col md={6} xs={12} lg={6}>
                <Fade bottom>
                  <div className="quote-align">
                    <h1 className="quote">
                      Education <br /> that gets you industry-ready
                    </h1>
                    <br />
                    <h6
                      className="quote-desc"
                      style={{ color: "#202b5d", paddingBottom: "5%" }}
                    >
                      College & bookish knowledge doesn't prepare you for
                      real-world and modern careers.Travail Academy, co-creates
                      programs with top industry professionals who can help you
                      get years ahead in your career.
                    </h6>
                    <br />
                    <a
                      data-text="Get Started"
                      className="quo"
                      style={{ alignSelf: "center" }}
                      href="#contact"
                    >
                      Get Started
                    </a>
                  </div>
                </Fade>
              </Col>
              <Col md={6} xs={12} lg={6}>
                <Bounce>
                  <img src={img} className="pic_acc" />
                </Bounce>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <br />
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Our Academy
          </h1>
          <br />
          <br />
          <div className="academy-container">
            <div className="academy-1">
              <Row>
                <Col>
                  <p className="academy-head highlight">100+</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Total Students</p>
                </Col>
              </Row>
            </div>
            <div className="academy-2">
              <Row>
                <Col>
                  <p className="academy-head highlight1">10+</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Programs</p>
                </Col>
              </Row>
            </div>
            <div className="academy-3">
              <Row>
                <Col>
                  <p className="academy-head highlight2">8.1/10</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="academy-content">Average Rating</p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <br />
        <br />
        <div id="special">
          <div>
            <h1
              className="h-heading"
              style={{
                color: "#202b5d",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              We specialize in?
            </h1>
            <br />
            <LightSpeed left>
              <div className="splbox">
                <Row style={{ margin: "0px" }}>
                  <Col md="4" lg="3" sm="6" xs="6">
                    <div className="splimgbox">
                      <img src={iot} className="specialimg" />
                      <p>IoT</p>
                    </div>
                  </Col>
                  <Col md="4" lg="3" sm="6" xs="6">
                    <div className="splimgbox">
                      <img src={blockchain1} className="specialimg" />
                      <p>Blockchain</p>
                    </div>
                  </Col>
                  <Col md="4" lg="3" sm="6" xs="6">
                    <div className="splimgbox">
                      <img src={ai1} className="specialimg" />
                      <p>Artificial Intelligence</p>
                    </div>
                  </Col>
                  <Col md="4" lg="3" sm="6" xs="6">
                    <div className="splimgbox">
                      <img src={ml1} className="specialimg" />
                      <p>Machine Learning</p>
                    </div>
                  </Col>
                  <Col md="4" lg="3" sm="6" xs="6">
                    <div className="splimgbox">
                      <img src={chatbot} className="specialimg" />
                      <p>Chat Bots</p>
                    </div>
                  </Col>
                  <Col md="4" lg="3" sm="6" xs="6">
                    <div className="splimgbox">
                      <img src={vr1} className="specialimg" />
                      <p>Virtual Reality</p>
                    </div>
                  </Col>
                  <Col md="4" lg="3" sm="6" xs="6">
                    <div className="splimgbox">
                      <img src={ar1} className="specialimg" />
                      <p>Software Development</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </LightSpeed>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div>
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            An immersive learning experience
          </h1>
          <br />
          <div className="ace-container">
            <div>
              <Row>
                <Col>
                  <Icon
                    className="ace-icon"
                    icon="mdi:marker-tick"
                    color="#1d60e3"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head">
                    Develop skills for
                    <br />
                    real career Travail
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content">
                    Cutting edge curriculam designed in guidance with industry
                    and academia to develop job skills.
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <Icon
                    className="ace-icon"
                    icon="clarity:assign-user-solid"
                    color="#1d60e3"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head">
                    Learn from experts active in their field, not out-of-touch
                    trainers
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content">
                    Leading practitioners who bring current best practices and
                    case studies to sessions that for into your work schedule
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <Icon
                    className="ace-icon"
                    icon="ic:outline-code"
                    color="#1d60e3"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head">
                    Learn by working on real-world problems
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content">
                    Capstone projects involving <br /> real world data sets with
                    virtual labs for hands-on learning
                  </p>
                </Col>
              </Row>
            </div>
            <div>
              <Row>
                <Col>
                  <Icon
                    className="ace-icon"
                    icon="mdi:alarm-clock-check"
                    color="#1d60e3"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="ae-head">
                    Structured guidance ensuring learning never stops
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <p className="h-content">
                    24 X 7 Learning support from mentors and a community of
                    like-minded peers to resolve any conceptual doubts
                  </p>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div>
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Our alumni work at reputed{" "}
            <span style={{ fontWeight: "bolder" }}>tech organizations</span> and{" "}
            <span style={{ fontWeight: "bolder" }}>promising startups</span>
          </h1>
          <br />
          <Fade>
            <Col md="2" />
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <img src={company} style={{ width: "80%" }} />
            </Col>
            <Col md="3" />
          </Fade>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div>
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            What makes us different?
          </h1>
          <br />
          <div>
            <Row style={{ margin: "0px" }}>
              <Col md="6" lg="6" sm="0" xs="0">
                <Bounce>
                  <img
                    src={grp}
                    alt="global"
                    width="100%"
                    height="100%"
                    className="pic_acc1"
                  />
                </Bounce>
              </Col>
              <Col md="6" lg="6" sm="12" xs="12">
                <Fade right>
                  <div className="ex-container">
                    <img src={message} alt="message" width="60%" height="75%" />
                    <span className="text">
                      <span className="a-head ae-head">
                        Interact with the best
                      </span>
                      <br />
                      Go beyond pre-recorded courses with Travail School LIVE
                      and get doubts cleared personally
                    </span>
                  </div>

                  <hr />
                  <div className="ex-container">
                    <img src={group} alt="user" width="60%" height="75%" />
                    <span className="text">
                      <span className="a-head ae-head">
                        The community at the center
                      </span>
                      <br />
                      Enjoy the best of peer-led learning with tons of events
                      while building lifelong relationships
                    </span>
                  </div>
                  <hr />
                  <div className="ex-container">
                    <img src={single} alt="study" width="60%" height="75%" />
                    <span className="text">
                      <span className="a-head ae-head">Learn by Doing</span>
                      <br />
                      Actionable programs to offset the Pareto Principle; you
                      will execute projects using what you learned
                    </span>
                  </div>

                  <hr />
                </Fade>
              </Col>

              <Col />
            </Row>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div>
          <h1 className="quote" style={{ textAlign: "center" }}>
            Community-led learning is the future
          </h1>
          <h6 className="acc-cont" style={{ textAlign: "center" }}>
            We at Travail school facilitate a dynamic community to build
            real-world probem solving skills
          </h6>
          <br />
          <br />
          <Row style={{ margin: "0px" }}>
            <Col md="6" lg="6" sm="12" xs="12">
              <Fade right>
                <div className="ex-container">
                  <img src={numone} alt="number_one" width="60%" height="75%" />
                  <span className="text">
                    <span className="a-head ae-head">
                      Build relationship beyond networks
                    </span>
                    <br />
                    Work with motivated peers having fresh out-of-the-box
                    thinking and showing you new ways to do things
                  </span>
                </div>

                <hr />
                <div className="ex-container">
                  <img src={numtwo} alt="number_two" width="60%" height="75%" />
                  <span className="text">
                    <span className="a-head ae-head">
                      Live events to engage in
                    </span>
                    <br />
                    Participate in live Q&As, debates, hackathons, and other
                    healthy contests to drive home the learning
                  </span>
                </div>
                <hr />
                <div className="ex-container">
                  <img
                    src={numthree}
                    alt="number_three"
                    width="60%"
                    height="75%"
                  />
                  <span className="text">
                    <span className="a-head ae-head">
                      Find career opportunities
                    </span>
                    <br />
                    Become a part of an ecosystem where members help access
                    opportunities for jobs and freelance projects
                  </span>
                </div>

                <hr />
              </Fade>
            </Col>
            <Col md="6" lg="6" sm="0" xs="0">
              <Bounce>
                <img
                  src={ace1}
                  alt="global"
                  width="100%"
                  height="100%"
                  className="pic_acc1"
                />
              </Bounce>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <br />
        <br />
        <div>
          <h1
            className="h-heading"
            style={{
              color: "#202b5d",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Questions?
          </h1>
          <br />
          <div>
            <Row style={{ margin: "0px" }}>
              <Col md="6" lg="6" sm="0" xs="0">
                <Fade>
                  <img
                    src={question}
                    alt="discussion"
                    width="100%"
                    height="100%"
                    className="pic_acc1"
                  />
                </Fade>
              </Col>
              <Col md="6" lg="6" sm="12" xs="12">
                <Fade right>
                  <div class="faq-container">
                    <div class="faq active">
                      <h3 class="faq-title">Who can take this training?</h3>
                      {this.state.qfa1 && (
                        <p class="faq-text">
                        College student who want to make them solid tech engineer and stand out of crowd for the future jobs opportunity.
                        </p>
                      )}
                      {this.state.ques1 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques1: false }, this.toggle)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques1: true }, this.toggle)
                          }
                        />
                      )}
                    </div>
                    <br/>
                    <div class="faq active">
                      <h3 class="faq-title">What are the pre-requisites?</h3>
                      {this.state.qfa2 && (
                        <p class="faq-text">
                        You need to have a laptop and dedication to learning development.
                        </p>
                      )}
                      {this.state.ques2 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques2: false }, this.toggle1)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques2: true }, this.toggle1)
                          }
                        />
                      )}
                    </div>
                     <br/>
                    <div class="faq active">
                      <h3 class="faq-title">How much is the fee for this training?</h3>
                      {this.state.qfa3 && (
                        <p class="faq-text">
                         The course fee is highly affordable and we provide liberty 
                         to split the payments as during training & after placement. 
                         Also, you can pay in installments
                        </p>
                      )}
                      {this.state.ques3 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: false }, this.toggle2)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques3: true }, this.toggle2)
                          }
                        />
                      )}
                    </div>
                    <br/>
                    <div class="faq active">
                      <h3 class="faq-title">Do you provide placement assistance?</h3>
                      {this.state.qfa4 && (
                        <p class="faq-text">
                         Definitely Yes. We just expect you to practice more & 
                         more to get placed. We keep referring you for job opportunities till
                         you get placed.
                        </p>
                      )}
                      {this.state.ques4 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques4: false }, this.toggle3)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques4: true }, this.toggle3)
                          }
                        />
                      )}
                    </div>
                    <br/>
                    <div class="faq active">
                      <h3 class="faq-title">Is online training available?</h3>
                      {this.state.qfa5 && (
                        <p class="faq-text">
                          Yes, you can join our training in online module as well.
                        </p>
                      )}

                      {this.state.ques5 ? (
                        <IoIosArrowDropdownCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques5: false }, this.toggle4)
                          }
                        />
                      ) : (
                        <IoIosArrowDroprightCircle
                          class="faq-toggle"
                          onClick={() =>
                            this.setState({ ques5: true }, this.toggle4)
                          }
                        />
                      )}
                    </div>
                  </div>
                </Fade>
              </Col>

              <Col />
            </Row>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <Col>
          <h4 className="h-heading" align="center">
            There is no alternative to stand out of the crowd
          </h4>
        </Col>
        <a href="/#contact">
          <p className="contact-us-rd" align="center">
            Contact Us
          </p>
        </a>
        <br />
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
