import React, { Component } from "react";
import "../../Styles/CareerCards.css";
import { Button, Row, Col } from "react-bootstrap";
import Footer from "../footer";
import "../../Styles/navbar.css";
import { Link } from "react-router-dom";
import RoutingNavbar from "../RoutingNavbar";

class CareerCards extends Component {
  render() {
    return (
      <div>
        <div>
          <div>
            <RoutingNavbar />
          </div>
          <br />
          <br />{" "}
          <div className="career-top">
            <p className="career-tophead" align="center">
              Let's Make Difference
              
            </p>
            <br/>
            <br/>
          </div>
          <div className="career-card-container">
          
           
            
            <div className="career-conatiner">
              <div className="careercard-1">
                
                <Row>
                  <Col className="job-head">
                    {" "}
                  Backend Developer
                  </Col>
                </Row>
                <Row>
                  <Col className="job-location" >
                    {" "}
                    CHENNAI &nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp; ENGINEERING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="job-content" >
                    Software Developer
                  </Col>
                </Row>
                
                <Row >
                  <Col md="11" xs={12} sm={12} style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a  href="/Career/CareerDetailsBackend" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                    <Button className="btn btn-primary" style={{ background:'#202b5d', borderColor:'#202b5d' }} >
                      Apply
                    </Button>
                    {" "}
                  </a>
                  </Col>
                
                </Row>
                
                 
                
              </div>
              <div className="careercard-2">
              <Row>
                  <Col className="job-head">
                    {" "}
                    Frontend Developer
                  </Col>
                </Row>
                <Row>
                  <Col className="job-location" >
                    {" "}
                    CHENNAI&nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp;ENGINEERING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="job-content" >
                    Software Developer
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsFrontend" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                    <Button style={{ background:'#202b5d', borderColor:'#202b5d' }} >
                      Apply
                    </Button>
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
              <div className="careercard-3">
              <Row>
                  <Col className="job-head">
                    {" "}
                    Software Development Engineer in Test
                  </Col>
                </Row>
                <Row>
                  <Col className="job-location" >
                    {" "}
                    CHENNAI &nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp; ENGINEERING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="job-content" >
                     Software Tester
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsTesting" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                    <Button style={{ background:'#202b5d', borderColor:'#202b5d' }} >
                      Apply
                    </Button>
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
              <div className="careercard-4">
              <Row>
                  <Col className="job-head">
                    {" "}
                    Senior Data Scientist
                  </Col>
                </Row>
                <Row>
                  <Col className="job-location" >
                    {" "}
                    CHENNAI &nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp; ENGINEERING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="job-content" >
                    Data Scientist
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsDataScientist"  style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                    <Button style={{ background:'#202b5d', borderColor:'#202b5d' }} >
                      Apply
                    </Button>
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
              <div className="careercard-5">
              <Row>
                  <Col className="job-head">
                    {" "}
                    Digital Marketing Associate
                  </Col>
                </Row>
                <Row>
                  <Col className="job-location" >
                    {" "}
                    CHENNAI &nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp;MARKETING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="job-content" >
                     Marketing
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsDigitalMarketing" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                    <Button style={{ background:'#202b5d', borderColor:'#202b5d' }} >
                      Apply
                    </Button>
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
              <div className="careercard-6">
              <Row>
                  <Col className="job-head">
                    {" "}
                    (Senior) Talent Acquisition Specialist
                  </Col>
                </Row>
                <Row>
                  <Col className="job-location" >
                    {" "}
                    CHENNAI&nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp;HR &
                    FACILITIES
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="job-content" >
                     Hr & facilities
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsHR" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                    <Button style={{ background:'#202b5d', borderColor:'#202b5d' }} >
                      Apply
                    </Button>
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
            </div>
            <br/>
          </div>
        </div>
        <br />
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default CareerCards;
