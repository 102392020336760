import React from 'react';

import axios from 'axios';

class Post extends React.Component {
  constructor(props) {
    super(props);
    const { steps } = this.props;
    const { name, email,phone } = steps;
  /*  const [steps,setData]=useState({
        name:"",
        email:"",
        phone:"",
        submit:"",
    })*/

    this.state= { name,email,phone }
    
  }

  /*then((response)=>{
    if (response.data.status === 'success'){
        alert("Message Sent."); 
        this.resetForm()
    }else if(response.data.status === 'fail'){
        alert("Message failed to send.")
    }
  })*/


  componentDidMount() {
  /* console.log("name:",this.state.name.value) 
   console.log("email:",this.state.email.value)
   console.log(" phone:",this.state.phone.value)*/ 
    
   const userObject = {
        name:this.state.name.value,
        email:this.state.email.value,
        phone:this.state.phone.value,
      };
      axios({
        method: "POST", 
        url:"http://localhost:3003/send", 
        data:  userObject
      }).then((response)=>{
        if (response.data.status === 'success'){
            alert("Message Sent."); 
            
        }else if(response.data.status === 'fail'){
            alert("Message failed to send.")
        }
      })
};


 
  render() {
      return (

       
         <div>Thank you! Your data was submitted successfully! We will get back to you!</div>
               
        
       
        );
      }
    };


  export default Post;
