import React, { Component } from "react";
import { Col, Row, Container } from "reactstrap";
import Bounce from "react-reveal/Bounce";
import service from "../images/service.svg"
import productservice from "../images/productEngineeringService.png"
class IndustriesWeServe extends Component {
  render() {
    return (
      <div>
        <br />

        {/* <div className="header">
          <Bounce bottom>
            <Row style={{ margin: "0px" }}>
              <Col lg="2" xl="2" md="2" sm="0" xs="0" />
              <Col>
                <h2
                  className="quote"
                  style={{
                    color: "#202b5d",
                    textAlign: "center",
                    paddingLeft: "3%",
                  }}
                >
                  Industries we serve
                </h2>
               
              </Col>
              <Col lg="2" xl="2" md="2" />
            </Row>
          </Bounce>
        </div> */}
       
        {/* <div><img src={productservice}  width="60%" style={{marginLeft:"18%"}} />
        <br/></div> */}
       
       <Container>
        <img src={service}   /></Container> 
        {/* className="industry-img" */}
        </div>
    );
  }
}
export default IndustriesWeServe;