import React, { Component } from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import "../Styles/NewService.css";
import { Timeline } from "react-beautiful-horizontal-timeline";
import "../../node_modules/react-beautiful-horizontal-timeline/card.css";
import RoutingNavbar from "./RoutingNavbar";
import services from "../images/newservices.PNG";
import { Link } from "react-router-dom";
import Footer from "./footer";
import process from '.././images/process.jpg'
import { AiOutlineDeploymentUnit,AiOutlinePieChart } from "react-icons/ai";
import { BsSearch, BsCheck2All,BsCloudArrowDown } from "react-icons/bs";
import { BiTargetLock } from "react-icons/bi";
import { MdQuestionAnswer, MdFindReplace } from "react-icons/md";
import { SiMicrostrategy, SiMaterialdesignicons } from "react-icons/si";
import { FaProductHunt } from "react-icons/fa";
import { VscBook,VscHeart } from "react-icons/vsc";
import sdlc from "../images/sdlc.png";
import SDL from "../images/SDL.png"
import serviceImage from '../images/serviceImage.png'
const data = [

  {
    s: "Ideation",
  },
  {
    s: "Deep Analysis",
    t: "on Feasibility",
  },
  {
    s: "Technology Solution",
    t: "to scale & sustain",
  },
  {
    s: "Conceptualize",
    t: "Strategy",
  },
  {
    s: "Product Definition",
  },
  {
    s: "Identfy Outliers",
  },
  {
    s: "Prototype &",
    t: "Design",
  },
  {
    s: "Extensive Testing",
  },
  {
    s: "Deployment",
  },
];
export class NewServices extends Component {
  render() {
    return (
      <div>
        <div>
          {" "}
          <RoutingNavbar />
        </div>
        <br />
        <br />
        <div className="career-top">
          <p className="career-tophead" align="center">
            Turn your business problem to 
            digital solutions

          </p>
          <br/>
          <br/>
        </div>
        <div className="whychoose-container">
          <div className="whychoose-1">
            <Row style={{ margin: "0px", paddingTop: "5%" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <div class="circle">
                  <BsCloudArrowDown
                    size={23}
                    style={{
                      fontWeight: "lighter",
                      verticalAlign: "middle",
                      marginTop: "23%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "13%" }}>
              <Col className="whychoose-head">We're Innovators</Col>
            </Row>
            <Row style={{ paddingTop: "5.5%" }}>
              <Col md="12">
                <p style={{ fontSize: "13.5px", lineHeight: "1.5" }}>
                 Our team is passionate about opportunities to innovate and find
                 amazing ways to solve complex business challenges.Travail design and
                 build solutions for clients that save money, increase efficiency and 
                 generate revenue.
                </p>
              </Col>
            </Row>
          </div>
          <div className="whychoose-2">
            <Row style={{ margin: "0px", paddingTop: "5%" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <div class="circle">
                  <AiOutlinePieChart
                    size={23}
                    style={{
                      fontWeight: "lighter",
                      verticalAlign: "middle",
                      marginTop: "23%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "13%" }}>
              <Col className="whychoose-head">We're Entrepreneurical</Col>
            </Row>
            <Row style={{ paddingTop: "5.5%" }}>
              <Col md="12">
                <p style={{ fontSize: "13.5px", lineHeight: "1.5" }}>
                 Travail has sat on the board of ambitious tech startups and had a pivotal
                 role in making them a commerical success.We can help shape projects at a 
                 strategic level and not merely deliver the technical challenge at hand.
                </p>
              </Col>
            </Row>
          </div>
          <div className="whychoose-3">
          <Row style={{ margin: "0px" }}>
            <Col >
          <div
              style={{
                textAlign: "center",
                fontWeight: "800",
                fontSize: "35px",
                color: "#202B5D",
                letterSpacing: "1.7px",
              }}
            >
              Why Choose Us
            </div>
            </Col>
            </Row>
          <Row style={{ margin: "0px" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <img src={serviceImage} alt="why choose us" width="100%" height="100%" />
                </Col>
                </Row>
            {/* <div
              style={{
                textAlign: "left",
                fontWeight: "800",
                fontSize: "35px",
                color: "#202B5D",
                letterSpacing: "1.7px",
              }}
            >
              Why Choose Us
            </div>
            <Row>
              <Col
                style={{
                  textAlign: "left",
                  paddingTop: "3%",
                  fontSize: "15.5px",
                  lineHeight: "2",
                }}
              >
                At DigiNadu, we believe in doing things differently. We believe
                in coming up with ideas and strategies that are unique and in
                line with your brand. We aim to bring your brand to life by
                weaving your brand story and connecting it with your audience.
                Our goal, as a digital marketing and technology company, is to
                come up with high-end proposals that will transform and boost
                your brand.
                <br />
                <br />
                We, at DigiNadu, are a young team of creative, vibrant
                professionals that come together to give you an experience like
                none other. We ensure that you are as much a part of the team as
                any other member, and together we aim to create interactive
                content that generates the desired results.
              </Col>
            </Row>
            <Row style={{ paddingTop: "10%" }}>
              <Col>
                <button
                  style={{
                    borderRadius: "50px",
                    display: "flex",
                    justifyContent: "left",
                    background: "#202B5D",
                  }}
                >
                  Explore more
                </button>
              </Col>
            </Row> */}
          </div>
          <div className="whychoose-4">
            <Row style={{ margin: "0px", paddingTop: "5%" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <div class="circle">
                  <VscBook
                    size={23}
                    style={{
                      fontWeight: "lighter",
                      verticalAlign: "middle",
                      marginTop: "23.6%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "13%" }}>
              <Col className="whychoose-head">A 360 Partnership</Col>
            </Row>
            <Row style={{ paddingTop: "5.5%" }}>
              <Col md="12">
                <p style={{ fontSize: "13.5px", lineHeight: "1.5" }}>
                 As an end to end partner,we will see your venture through to success from
                 initial idea to flawless execution,ongoing support and steering business 
                 growth.We've helped shaped vision , write business plans,raise funding,
                 setup support and hosting ecosystems and market ventures successfully.
                </p>
              </Col>
            </Row>
          </div>
          <div className="whychoose-5">
            <Row style={{ margin: "0px", paddingTop: "5%" }}>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <div class="circle">
                  <VscHeart
                    size={23}
                    style={{
                      fontWeight: "lighter",
                      verticalAlign: "middle",
                      marginTop: "24%",
                    }}
                  />
                </div>
              </Col>
            </Row>
            <Row style={{ paddingTop: "13%" }}>
              <Col className="whychoose-head">Nimble & Agile</Col>
            </Row>
            <Row style={{ paddingTop: "5.5%" }}>
              <Col md="12">
                <p style={{ fontSize: "13.5px", lineHeight: "1.5" }}>
                 Our lack of bureaucracy and flat company structure makes us agile enough
                 to deliver results and get ideas to market at a speed that will delight you.
                 We will reduce development time and exponentially increase ROI.
                </p>
              </Col>
            </Row>
          </div>
        </div>
        <h4 className="h-heading" align="center" style={{}}>
        Industries We Serve
        </h4>
        <div className="industries-container" style={{ justifyContent:'center', display:'flex'}}>
           <img  width='65%' height='auto' src={SDL} /> 
        </div>
       
        <h4 className="h-heading" align="center">
          How do we do it?
        </h4>
        <div className="howdowedo-container">
          <div className="howdowedo-1">
          <Row>
            <Col>
              <BiTargetLock className="howdo-icon" />
            </Col>
          </Row>
          <Row>
           <Col>
              <p className="howdo-head">Ideation</p>
                </Col>
                </Row>
                <Row>
                  <Col>
                <p className="h-content">
                  Ideation comprises all stages of a thought cycle, from
                  innovation, to development, to actualization
                </p>
                </Col>
                </Row>
         
            
            </div>
            <div className="howdowedo-2">
            <Row >
            <Col>
              
                <BsSearch className="howdo-icon" />
               </Col>
                </Row>
                <Row >
            <Col>
                <p className="howdo-head">Deep Analysis</p>
                </Col>
                </Row>
                <Row >
            <Col>
                <p className="h-content">

                  Conducting a Market Feasibility Analysis for new products is a
                  critical step in the product development process, sizing the
                  potential market, and ultimately determining market
                  feasibility
                </p>
                </Col>
                </Row>
      
           
            </div>
            <div className="howdowedo-3">
              
            <Row >
            <Col>
                <MdQuestionAnswer className="howdo-icon" />
                </Col>
                </Row>
                <Row >
            <Col>
                <p className="howdo-head">Technology Solution</p>
                </Col>
                </Row>
                <Row >
            <Col>
                <p className="h-content">
                  Scaling is the activity that gives the product sustainability
                  and stability. A series of small but significant tested
                  iterations that respond to, and anticipate, the market’s
                  requirements
                </p>
             </Col>
             </Row>
          

            </div>
            <div className="howdowedo-4">
              
            <Row >
            <Col>
              
                <SiMicrostrategy className="howdo-icon" />
                </Col>
            </Row>
                <Row >
            <Col>
                <p className="howdo-head">Conceptualize Strategy</p>
                </Col>
            </Row>
                <Row >
            <Col>
                <p className="h-content">
                  When conceptualizing a new idea, it is essential to direct the
                  thinking to specific dimensions and search answers which helps
                  to evolve the idea from the initial thought through the
                  various stages of innovation
                </p>
     
            </Col>
            </Row>
            </div>
            <div className="howdowedo-5">
            <Row >
            <Col>
              
                <FaProductHunt className="howdo-icon" />
                </Col>
            </Row>
                <Row >
            <Col>
                <p className="howdo-head">Product Definition</p>
                </Col>
            </Row>
                 <Row >
            <Col>
                <p className="h-content">
                  Define the core purpose.Let us work out the purpose of our
                  product by asking a set of simple but revealing questions
                </p>
        
            </Col>
            </Row>
            </div>
            <div className="howdowedo-6">
            <Row >
            <Col>
              
                <MdFindReplace className="howdo-icon" />
                </Col>
            </Row>
                <Row >
            <Col>
               
                <p className="howdo-head">Identify outliers</p>
                </Col>
            </Row>
                <Row >
            <Col>
                <p className="h-content">
                  Outliers are extreme values that deviate from other
                  observations on data , they may indicate a variability in a
                  measurement, experimental errors or a novelty
                </p>
           
            </Col>
          </Row>
    </div>
   
            <div className="howdowedo-7">
         
    
          <Row >
            <Col>
              
                <SiMaterialdesignicons className="howdo-icon" />
                </Col>
            </Row>
                <Row >
            <Col>
                <p className="howdo-head">Prototype & Design</p>
                </Col>
            </Row>
                <Row >
            <Col>
                <p className="h-content">
                  Prototyping is an experimental process where design teams
                  implement ideas into tangible forms from paper to digital.With
                  prototypes, you can refine and validate your designs so your
                  brand can release the right products
                </p>
           
            </Col>
            </Row>
            </div>
            <div className="howdowedo-8">
              <Row>
            <Col>
              
                <BsCheck2All className="howdo-icon" />
                </Col>
            </Row>
                <Row >
            <Col>
                <p className="howdo-head">Extensive Testing</p>
                </Col>
            </Row>
                <Row >
            <Col>
                <p className="h-content">
                  This solution is an open and generic test automation framework
                  designed to make integration, validation, regression and
                  end-to-end testing with a collaborative workspace environment
                </p>
             
            </Col>
            </Row>
            </div>
            <div className="howdowedo-9">
              <Row>
            <Col >
              
                <AiOutlineDeploymentUnit className="howdo-icon" />
                </Col>
            </Row>
            <Row>
            <Col >
                <p className="howdo-head">Deployment</p>
                </Col>
            </Row>
            <Row>
            <Col >
                <p className="h-content">
                  Software deployment is all of the activities that make a
                  software system available for use. The general deployment
                  process consists of several interrelated activities with
                  possible transitions between them
                </p>
              
            </Col>
          </Row>
</div>
</div>
        <Row style={{ margin: "0px" }}>
          <Col md="1" />
          <Col md="10" xs={12}>
            <h4 className="h-heading" align="center">
              Our Process
            </h4>
            <p className="h-content" align="center">
              Our approach to solve your business problem
              towards the next breakthrough. Attain the outcomes that are most
              critical to help you run and transform your business through our
              unique set of processes.
            </p>
          </Col>
          <Col md="1" />
        </Row>
      
<Container>
        <Row style={{margin:'0px'}}>
          <Col md="12">
          <img src={process} style={{width:'100%'}}/>

          </Col>
        </Row>
</Container>
        <h4 className="h-heading" align="center">
        There is no alternative to digital transformation
        </h4>
        <div style={{ display:'flex', justifyContent:'center'}}>
        <a href="/#contact">
          <div className="getdemo-center" align="center">
            Contact Us
          </div>
         
        </a>
        </div>
        <br />
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default NewServices;
