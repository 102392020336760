import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/CareerCards.css";
import Footer from "../footer";
import {Link} from 'react-router-dom'

class CustomerSuccessRole extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <div>
      <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Job Details</p>
     </div>
      <div className="details">
        <div className="career-details">
          <div>
              <br/><br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
             Customer Success Manager
            </div>
            <br />

            <p>
              While Travail has had incredible organic growth over the last few
              years the company also has made targeted acquisitions that add
              critical capabilities to the portfolio including Natural Language
              Processing, Chatbots, Machine Learning, Social and Messaging
              Transformation. At Travail, we are creating a global workplace
              that enables everyone to find their true potential, purpose and
              passion, irrespective of their background, gender, race, sexual
              orientation, religion or ethnicity. We are committed to providing
              equal opportunity for all and believe that diversity in the
              workplace creates a more vibrant, richer work environment that
              advances the goals of our employees, our communities and our
              business.
            </p>
          </div>
          <div style={{fontSize:'20px'}}>
           <b> <p>Your role and responsibilities:</p></b>
            
          </div>
          
          <div>
         
            <b>1. Build deep relationships and drive product adoption</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>
              Work along with a pre-defined group of our largest and most important customers.
               Establish connections to partners ranging from support agents to CxOs.
              </li>
             <li> Engage with customers in a dedicated and regular manner e.g. in quarterly business reviews,
                  roadmap presentations and product trainings</li>
               <li>Understand and document customer’s organization, its needs, goals and challenges.</li>
            <li>Educate and train customers on the best ways to use our products and its new features.
                 Help them to follow data driven approaches and measure KPIs.</li>
            </ul>
          </div>
          <div>
            <b>2. Retain customers</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>
              Analyze product usage data to actively take necessary actions to improve product adoption and reduce churn
              </li>
             <li> Conduct diagnostics and identify gaps in the customer’s product setup and processes.
                  Evangelize and guide customers to implement improvements.</li>
               <li>Develop creative solutions in collaboration with technical experts in case the 
                   standard feature set is not sufficient to meet customer needs.</li>
            
            </ul>
          </div>
          <div>
            <b>3. Inspire customer growth and advocacy</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>
              Work together with the Account Management team to discover opportunities for growth and drive net expansion.
              </li>
             <li> Promote awareness of Travail latest innovations and motivate customers to become references, 
                 participate in events and contribute to case studies.</li>
               <li>Find new and creative ways to create “moments of wow” for our customers.</li>
            
            </ul>
          </div>
          <div>
            <b>Our perfect match</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
            <li>2-3 years of experience in a customer facing role. Experience with consulting or account management a big plus</li>
            <li>Bachelor's degree. Engineering background is advantageous.</li>
            <li>Communicates clearly and with empathy to become a trusted advisor for our clients.</li>
            <li>Collaborates effectively with different internal teams (e.g., sales, marketing, product, support).</li>
            <li>Enjoys learning about Travail products and their technical foundation.</li>
            <li>Shows business insight to understand client’s needs and keeps a professional attitude.</li>
            <li>Combines an analytical mindset with a can-do attitude. Identifies root causes and develops adequate solutions quickly.</li>
            <li>Manages multiple projects at ease and can quickly switch context from technical to business topics.</li>
            <li>Willingness to travel, as much as 25% of the time will be spent at client’s offices</li>
            <li>Fluency in English and Dutch. Fluency in other EU languages is a plus.</li>
            </ul>
          </div>

          <div>
            <b>These are some benefits you can expect from us in return:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              
            <li>Great chance to define the European presence of a dynamic hyper-growth global company</li>
              
              <li>Flexible career development path within an international environment. We are just getting started!</li>
              <li>Work closely with smart, inspiring and encouraging international colleagues within a flat organizational structure</li>
              <li>An excellent compensation package, including bonus and equity</li>
            
            </ul>
          </div>
          <br />
          <div>
            {" "}
            <Link to="/apply-jobs" style={{textDecoration:'none'}}>
            <Button
              className="apply-now"
              style={{
                height: "50px",
                fontWeight: "700",
              }}
            >
              APPLY NOW
            </Button>{" "}
            </Link>
          </div>
          <br />
        </div>
        {/* {this.state.modal && (
          <Modal isOpen={this.state.modal} size="md" centered>
            <div className="modal-title">
              {" "}
              <ModalHeader toggle={this.toggle}>
                Join us and be a part of our great culture!
              </ModalHeader>
            </div>
            <ModalBody
              style={{
                maxHeight: "calc(70vh - 210px)",
                width: "95%",
                marginLeft: "1%",
                overflowY: "auto",
              }}
            >
              <Row style={{ margin: "0px", textAlign: "justify" }}>
                {" "}
                We never see resumes and degrees any papers. Kindly send us a
                mail with your name, number and your story and our team will
                respond to you.
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                <a href="https://mail.google.com">
                  balasubramaniyan@travailtechnologies.in
                </a>
              </div>
            </ModalBody>
            <br />
            <ModalFooter>
              <Button
                className="career-button-apply"
                style={{ width: "30%" }}
                //   style={{
                //     border: "1px solid #268da5",
                //     color: "#268da5",
                //     backgroundColor: "#fff",
                //   }}
                onClick={this.toggle}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        )} */}
      </div>
      <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default CustomerSuccessRole;
