import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/CareerCards.css";
import Footer from "../footer";
import {Link} from 'react-router-dom'

class CareerDetailsHR extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <div>
      <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Job Details</p>
     </div>
      <div className="details">
        <div className="career-details">
          <div>
              <br/><br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
            (Senior) Talent Acquisition Specialist
            </div>
            <br />

            <p>
              While Travail has had incredible organic growth over the last few
              years the company also has made targeted acquisitions that add
              critical capabilities to the portfolio including Natural Language
              Processing, Chatbots, Machine Learning, Social and Messaging
              Transformation. At Travail, we are creating a global workplace
              that enables everyone to find their true potential, purpose and
              passion, irrespective of their background, gender, race, sexual
              orientation, religion or ethnicity. We are committed to providing
              equal opportunity for all and believe that diversity in the
              workplace creates a more vibrant, richer work environment that
              advances the goals of our employees, our communities and our
              business.
            </p>
          </div>
         
          
          <div>
         
            <b>Your Role and Responsibilities:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
           <li> Own and drive end to end recruitment process from (sourcing, interviews, reference checks, negotiating compensation proposals
                and closing candidate offers)</li>
           <li>Ability to partner and influence stakeholders at all levels, including executives, to drive hiring goals and program outcomes.</li>
           <li>Build a pipeline of top candidates for different roles by using a variety of sourcing channels (inbound, outbound, agencies, etc.).
                The candidate has to have the ability to source through direct hires</li>
           <li>Ensure a high-touch experience for every candidate from first contact to final offer, flawless best in class onboarding and post onboarding check in</li>
           <li>Contribute to employer branding concepts and projects along with Marketing colleagues. Organize recruiting events</li>
           <li>Transformational mindset to drive our Talent Acquisition processes</li>
            </ul>
          </div>
         
          <div>
            <b>Our perfect match</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
            <li>2+ years of recruiting experience in a similar role, ideally with a focus on hiring sales people in an 
                agency or startup setting. SAAS experience preferred</li>
            <li>Knowledge in talent sourcing and full recruitment life cycle</li>
            <li>Experience in using HR technology and applicant tracking systems</li>
            <li>Teammate and strong collaborator with a strong work ethic and ownership who enjoys working in a 
                high-energy team environment and is passionate about other people</li>
            <li>Excellent communication skills</li>
            </ul>
          </div>

          <div>
            <b>These are some benefits you can expect from us in return:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              
            <li> Great opportunity to build the European presence of a fast-paced global growth startup from scratch</li>
            <li>Flexible career development path within an international environment</li>
            <li>A fun startup work environment in the middle of Berlin</li>
            <li>Work closely with inspiring, supportive and interesting colleagues in an
                 international team within a flat organizational hierarchy</li>
            <li>An experienced executive team of managers and experts</li>
            <li>Fully stocked pantry with healthy fruits, snacks, gourmet coffee, and breakfast options</li>
            <li>Weekly family lunch and quarterly team building events</li>
            
            </ul>
          </div>
          <br />
          <div>
            {" "}
            <Link to="/apply-jobs" style={{textDecoration:'none'}}>
            <Button
              className="apply-now"
              style={{
                height: "50px",
                fontWeight: "700",
              }}
            >
              APPLY NOW
            </Button>{" "}
            </Link>
          </div>
          <br />
        </div>
        {/* {this.state.modal && (
          <Modal isOpen={this.state.modal} size="md" centered>
            <div className="modal-title">
              {" "}
              <ModalHeader toggle={this.toggle}>
                Join us and be a part of our great culture!
              </ModalHeader>
            </div>
            <ModalBody
              style={{
                maxHeight: "calc(70vh - 210px)",
                width: "95%",
                marginLeft: "1%",
                overflowY: "auto",
              }}
            >
              <Row style={{ margin: "0px", textAlign: "justify" }}>
                {" "}
                We never see resumes and degrees any papers. Kindly send us a
                mail with your name, number and your story and our team will
                respond to you.
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                <a href="https://mail.google.com">
                  balasubramaniyan@travailtechnologies.in
                </a>
              </div>
            </ModalBody>
            <br />
            <ModalFooter>
              <Button
                className="career-button-apply"
                style={{ width: "30%" }}
                //   style={{
                //     border: "1px solid #268da5",
                //     color: "#268da5",
                //     backgroundColor: "#fff",
                //   }}
                onClick={this.toggle}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        )} */}
      </div>
      <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default CareerDetailsHR;
