import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/CareerCards.css";
import Footer from "../footer";
class Blog2 extends Component {
 
 
  render() {
    return (
      <div>
      <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Deep Tech: Now and in the Future</p>
     </div>
      <div className="details">
         
        <div className="career-details">
          <div>
          <br/>
            {/* <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
              Machine Learning Model Deployment
            </div>
            <br /> */}

            <p>
            Deep Tech companies are aiming to transform the world through scientific, engineering and 
            technological advances. As technology evolves, researchers are looking to apply engineering and 
            technological advances in areas such as processing and computing architecture, semiconductors and 
            electronics, materials science, vision and speech technologies, artificial intelligence (AI) and 
            machine learning, and so on – for the greater good. For example, finding a cure to a disease, 
            developing new medical devices, sensors and analytics to help farmers increase yield, or 
            developing clean energy solutions to reduce the environmental impact are some of the areas that Deep Tech is 
            finding real-world applications.
            </p>
            </div>
            
            <div>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
              Deep Tech Impacting Industries Today
            </div>
            
           
            <p>
            There are several industries that are benefitting from Deep Tech innovations today. 
            Here are only a few examples of Deep Tech innovations in some industries:
            </p>
            <b>Healthcare</b>
            <br/>
            <p>
            The combination of computational and biotechnology is accelerating the development of new cures, augmenting 
            R&D and improving health outcomes. Deep Tech in healthcare has multiple applications from the 
            manufacturing of affordable medical devices to redefining healthcare. Vibrosonic, has designed what they 
            call a “contact lens for the ear” which can be directly placed on the eardrum. 
            Unlike other hearing aids speakers are not used to transport sound through the ear canal 
            but the eardrums are stimulated through electric impulses. A Singapore-based biotech company X Zell 
            has patented a “liquid biopsy”- detecting cancer from a 10ml blood sample by measuring the presence of 
            tumour-derived Circulating Endothelial Cells (tCEC) – which reduces the need for invasive cancer detection 
            processes.
            </p>
            <b>Food and Agriculture</b>
            <br/>
            <p>
            Food crisis is a reality today with factors such as overpopulation, urbanisation, decreasing 
            land per capita, extreme climates and so on impacting the food and agriculture industry immensely. 
            Deep Tech companies are working to bring us sustainable food options and building climate 
            resilience. Cell-based meat options are being researched globally, and companies such as foodtech 
            start-up Shiok Meats is producing meat by harvesting cells from animals with a view to be 
            environmentally friendly and to reduce the impact on biodiversity. In agriculture, Deep Tech 
            companies are working on technologies to develop better farming methods to improve yield and 
            precision sensors for weather forecasting. Examples such as UbiQD, that has worked on a greenhouse 
            quantum dot film that improves crop quality by optimising sunlight spectrum for plants to improve 
            production, show how Deep Tech will continue to transform the industry.
            </p>
            <b>Environment and Energy</b>
            <br/>
            <p>
            Deep Tech continues to come up with solutions that will help us in climate change mitigation, 
            development of sustainable energy and energy efficiency. Innovations include Carbon Upcycling 
            Technologies’ solution to capture and neutralise carbon dioxide. The carbon dioxide-enriched 
            nanoparticles are used to make commercial construction materials and even consumer products such as jewellery. 
            Celadyne Technologies has developed hydrogen fuel cells and electrolysers with nanocomposite membranes 
            for a more efficient, cost-effective and eco-friendly energy source.
            </p>
            <b>Advanced Computing</b>
            <br/>
            <p>
            As technology evolves, there will be a need to support even greater compute and data-intensive tasks. 
            Deep Tech has impacted and will continue to impact advanced computing. The semiconductor and microchip 
            industry is getting disrupted by cutting-edge global research, many by the top universities. MIT, for 
            example, has developed a process called “remote epitaxy” to manufacture flexible chips. Potential use 
            cases include VR-enabled contact lenses, electronic fabrics that respond to the weather, and other 
            flexible electronics. Atom Computing is working on scalable quantum computing that will be able to scale 
            millions of qubits using individual atoms – without scaling up the physical resources – in a single 
            architecture.
            </p>
            <b>Communication and Security</b>
            <br/>
            <p>
            Communication and connectivity have seen a sea change in the last decade. As we wait for 5G 
            to take off, this industry has become a playground for inventions. Aircision, is working on making 
            5G more accessible using its laser-based communications technology. The technology is developed to 
            enable high-bandwidth communication and beam data between buildings thus aiming to eliminate the 
            need for optical fibre installations and microwave. Another area that will keep getting a lot of 
            attention from Deep Tech firms is communication security. Speqtral is working on space-based 
            quantum networks to deliver secure encryption keys.
            </p>
            <p>
            Examples such as these are an indication that Deep Tech is a reality today and has the 
            potential to disrupt several industries and impact the lives of millions.
            </p>
          </div>
          
          <div>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
              Where is Deep Tech Headed?
            </div>
            <br/>
            <b>Government Interest in Deep Tech</b>
            <br/>
            <p>
            Since Deep Tech is aimed at leveraging technology and engineering for sustainability and greater 
            good, several countries are promoting Deep Tech R&D and initiatives. From emerging to mature 
            economies, governments are supporting their Deep Tech industry. The New Zealand  Government has 
            formed a Deep Tech Incubator program. The program is headed by the Government’s innovation agency 
            to help Deep Tech companies and to create new tech jobs.
            </p>
           
            <p>
            Singapore has created a strong Deep Tech ecosystem leveraging the funding ecosystem, 
            the presence of global corporations, research and higher learning organisations and the Government 
            that promotes innovation and entrepreneurship. Agencies such as SGInnovate and Enterprise Singapore are 
            working with Deep Tech startups in advanced manufacturing, urban solutions and sustainability, and 
            healthcare and biomedical sciences. Partnerships between universities, industry bodies and research 
            organisations further fuel this ecosystem – the Critical Analytics for Manufacturing Personalised-Medicine 
            (CAMP) is a partnership between Singapore-MIT Alliance for Research and Technology (SMART) and 
            A*STAR for cell therapy manufacturing. The Government also funds and incentivises Deep Tech startups. 
            The 2020 budget announced additional funding to support Deep Tech companies under the Start-up SG Equity 
            scheme.
            </p>
           
            <p>
            As global governments get serious about the quality of their citizens’ lives and sustainability goals, 
            they will invest in Deep Tech research.
            </p>
            
            <br/>
            <b>Challenges of the Deep Tech Industry</b>
            <br/>
            <p>
            While Deep Tech has enormous potential, mainstream adoption is still some way off. There are some unique 
            challenges that the industry faces today. Future uptake will depend heavily on how fast the industry 
            can circumvent these challenges. The key challenges are:
            </p>
            <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>
              <b>Securing Finances.</b> Despite initiatives by several global governments, Deep Tech projects often find it 
              difficult to secure funding. Very often the research duration can stretch without any real guarantee of 
              success. Funding is likelier to go to organisations developing consumer products as the ROI are seen 
              earlier and are easier to quantify, especially in the early stages.
              </li>
              <li>
              <b>Identifying Market Opportunities.</b> Researchers who develop Deep Tech solutions and products might not be 
              able to identify opportunities to present their development from a marketing as well as an economic 
              perspective. Very often these companies rely on other channels or third-party services for a proper 
              marketing and planning strategy. This is where working with incubators or government bodies becomes 
              crucial – countries that give that opportunity through a well-defined ecosystem, will lead the Deep Tech 
              revolution.
              </li>
              <li>
              <b>Scalable Development.</b> Many Deep Tech innovations get stuck at the proof-of-concept stage – not 
              because they are not innovative enough, but because they are not scalable to mass production. 
              That requires the right infrastructure as well as a deep understanding of how the products and 
              services can be commercialised.
              </li> 
            </ul>
          </div>
          <div>
          <p>
          There are several global companies trying to disrupt entire industries with their inventive 
          offerings. We are witnessing some novel innovations in autonomous vehicles, foodtech, computer 
          vision, AI, weather predictions, Clean Energy solutions  – the list continues – that we will benefit 
          from in the future.
          </p>
          </div>

          </div>
          
        
         
          <br />
        </div>
       
      </div>
      <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Blog2;
