import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/CareerCards.css";
import Footer from "../footer";
class Blog3 extends Component {
 
 
  render() {
    return (
      <div>
      <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Artificial Intelligence</p>
     </div>
      <div className="details">
         
        <div className="career-details">
          <div>
          <br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
              What AI Means for Business
            </div>
           
            <br/>
            <p>
            Today, Artificial Intelligence and Machine Learning are seen as part of the everyday life of 
            large organizations in various fields. The rapid pace of AI development – achievements in unmanned 
            aerial vehicles, the ability to beat humans at chess and poker, automated customer service and analytical 
            systems – shows that AI is a revolutionary technology designed to change the way people use devices and 
            conduct business.
            </p>
            <p>
            The appearance of intelligent machines capable of solving difficult problems in a way that only 
            humans could previously hasn’t gone unnoticed. From the day of its invention until now, the AI 
            technology has been evolving and improving. Due to its ability to learn, AI is widely used in many 
            spheres. Particular applications of AI include expert systems, Speech Recognition, Machine Learning, 
            and Machine Vision.
            </p>
            <p>
            According to the 2019 CIO Survey by Gartner, the number of companies implementing AI technologies in some 
            form has grown by 270 percent in the past four years, and by 37 percent in the past year alone.
            </p>
            <p>
            It’s worth mentioning that AI in this context doesn’t relate to actual self-aware intelligence machines in a 
            pure form. Rather, it can be considered a general term for a range of applications used by website and 
            mobile app developers. They include image and Speech Recognition, Cognitive Computing, Automatic Analysis, 
            and Machine Learning.
            </p>
            <p>
            There are two main factors driving the fast adoption of AI. The first is access to high-quality, 
            adaptable learning models. The second is the need to manage large amounts of data in a cheaper and 
            more effective way instead of investing heavily in massive infrastructure.
            </p>
            
            </div>
            <div>
          <br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
              The Difference Between AI and ML
            </div>
           
            <br/>
            <p>
            <b>AI:</b> As Artificial Intelligence and Machine Learning have become integral for many businesses, 
            the terms are often used interchangeably. However, this is inaccurate. Let’s look into these technologies
             more closely to understand the difference between them and how to tell them apart.
            </p>
            <p>
            AI is a broad term that describes advanced computer intelligence. AI can include anything from programs 
            for playing chess to voice recognition assistants such as Apple’s Siri and Amazon’s Alexa. All AI 
            technology falls into one of three categories: narrow or weak AI, Artificial General Intelligence (AGI), 
            and Superintelligent AI.
            </p>
            <p>
            IBM’s program known as Deep Blue that beat Garry Kasparov at chess in 1996, and Google DeepMind’s 
            AlphaGo, which beat Lee Sedol at Go in 2016, are examples of narrow AI. These systems were designed to 
            perform only one task or solve only one problem. Artificial General Intelligence, on the contrary, is 
            considered to be on the same level as the human brain, as it can perform a wide range of tasks.
            </p>
            <p>
            Superintelligent AI is more advanced than Human Intelligence. According to AI guru Nick Bostrom, 
            Superintelligent AI can be described as “an intellect that is much smarter than the best human brains in 
            practically every field, including scientific creativity, general wisdom, and social skills.” The time may 
            come when machines surpass us on the intellectual level.
            </p>
            <p>
            <b>ML:</b> Machine Learning is just one of the many applications of AI. The key principle of Machine Learning is 
            that machines receive data and teach themselves based on it. These days, ML tools are the most highly 
            demanded AI-powered tools for businesses. Machine Learning systems are capable of applying knowledge 
            acquired via training from enormous sets of data in order to excel at facial and speech recognition, 
            object recognition, translation, chatbots, etc. While a hand-coded program requires specific instructions 
            to complete a task, ML learns to recognize patterns on its own to predict the next step.
            </p>
            <p>
            Deep Blue and DeepMind are types of AI. However, Deep Blue was based on a certain set of rules, so it can’t 
            be considered a Machine Learning system. Meanwhile, DeepMind’s AlphaGo is a perfect example of an ML 
            algorithm: it managed to beat the world champion in Go because it learned expert moves from experienced 
            players by analyzing a large amount of data.
            </p>
            
            </div>
            <div>
          <br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
             How Machine Learning Works
            </div>
           
            <br/>
            <p>
            So how exactly do machines teach themselves? According to the commonly accepted definition, 
            ML is the ability for computers to learn and act without being explicitly programmed. To enable this 
            self-learning function, ML uses two techniques: supervised learning and unsupervised learning.
            </p>
            <p>
            <b>Supervised learning</b> involves training a model on a known set of input data and known responses 
            to that data (outputs) so it can predict future responses to new data. In turn, supervised learning uses 
            classification and regression techniques to develop predictive models.
            </p>
            <p>
            Classification techniques predict discrete responses by classifying input data into categories or classes. 
            This type of ML is usually applied for image classification, diagnostics, identity fraud detection, and 
            speech recognition. For instance, ML can say whether an email is genuine or spam or whether a tumor is 
            malignant or benign.
            </p>
            <p>
            Regression techniques predict continuous responses to constantly changing or developing data. They help a 
            lot when it comes to weather forecasting, advertising popularity predictions, market predictions, and 
            algorithmic trading.
            </p>
            <p>
            Organizations usually choose supervised learning when their goal is to train a model to make a 
            prediction regarding the future value of a continuous variable, such as temperature or a stock price 
            (regression), or to identify makes of cars from webcam video footage (classification).
            </p>
            <p>
            <b>Unsupervised learning</b> models are capable of finding hidden patterns and intrinsic groupings within 
            input data and don’t require knowledge of the output for this.
            </p>
            <p>
            The most typical unsupervised learning technique is clustering. Applications of cluster analysis 
            include gene sequence analysis, recommender systems, targeted marketing, and object recognition.
            </p>
            <p>
            In practice, clustering can be implemented like this: Let’s say a cell provider wants to figure out the best 
            places to locate their cell phone towers. For this, they need to acquire information about the number of 
            users concentrated in each specific area around the company’s existing towers. Machine Learning can 
            estimate this number. As a phone can receive a signal only from one tower at a time, the telecom operator 
            can use a clustering algorithm to determine the best placement of cell towers to optimize signal reception 
            for different groups (clusters) of customers.
            </p>
            <p>
            Organizations usually rely on unsupervised learning if they need to explore their data and train a 
            model that will split data into clusters.
            </p>
            </div>
            <div>
          <br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
             Applications of ML
            </div>
           
            <br/>
            <p>
            Machine Learning has made its way into a variety of human activities. The most common uses of Machine Learning 
            are in the following four sectors:
            </p>
            <p>
            <b>Business.</b> Almost every organization dealing with customer service has embraced AI 
            technology in one one way or another. Machine Learning algorithms are being widely integrated into
            analytics and CRM platforms to uncover information on how to better serve customers. Chatbots are 
            being added to websites and mobile apps to provide immediate responses to visitors and users.
            </p>
            <p>
             Adding ML to a mobile app has become a common request from business owners.
             Mobile app developers have learned fast how to integrate machine learning into Android and 
             iOS apps. Apple offers developers their own Machine Learning frameworks: Core ML and the Create 
             ML app.
            </p>
            <p>
            <b>Education.</b> ML models can automate grading, giving teachers more time to concentrate on other aspects 
            of education. Other programs are capable of assessing students in their study, adapting to their needs and 
            level and helping them work at their own pace. There are even AI tutors that provide support to students, 
            ensuring they stay on track.
            </p>
            <p>
            <b>Healthcare.</b> ML algorithms are widely integrated in medical applications. With the help of 
            Machine Learning, doctors can make better and faster diagnoses and predict patient outcomes sooner.
            </p>
            <p>
            Chatbots are also being actively applied by Healthcare service providers, as these online programs can 
            assist patients in many ways such as scheduling appointments, answering common questions, aiding in the 
            payment process, and even providing basic virtual diagnostics.
            </p>
            <p>
            <b>Manufacturing.</b> Industrial companies have been at the forefront of incorporating robots into 
            the workflow. Industrial robots have helped to automate and optimize certain processes and tasks 
            previously performed by humans. This has helped to increase the volumes of manufactured products and 
            reduce labor costs.
            </p>
            </div>
            <div>
          <br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
             Which ML Frameworks Should You Consider?
            </div>
           
            <br/>
            <p>
            Since hardware, software, and staffing costs for AI can be high, many vendors are including AI components and 
            access to AI as a Service (AIaaS) platforms as part of their standard offerings. This way, organizations get a 
            chance to test AI-powered tools, especially programs with integrated ML, for various business purposes and sample 
            multiple platforms before making a commitment. The most popular ML frameworks include Microsoft Cognitive Toolkit, 
            Amazon Machine Learning, and PyTorch.
            </p>
            <p>
            <b>Microsoft Cognitive Toolkit (CNTK).</b> Microsoft created CNTK as a training algorithm for machines to 
            learn like humans. However, CNTK is used to create various Machine Learning models – for instance, 
            feed-forward DNNs, recurrent neural networks, and convolutional neural networks. Thanks to CNTK, it 
            becomes easier for neural networks to process huge amounts of unstructured data. The toolkit also offers 
            rapid training and intuitive architecture. It can be customized as per your requirements because it lets you 
            select your metrics, networks, and algorithms.
            </p>
            <p>
            <b>Amazon ML.</b> Amazon’s Machine Learning framework comprises a set of tools to create highly sophisticated, 
            intelligent, and high-end applications without code. AML provides predictions for the desktop and mobile 
            applications using APIs and can connect applications to the cloud.
            </p>
            <p>
            <b>PyTorch.</b> This tool serves as an ML library and scientific computing framework at the same time. 
            PyTorch is flexible and offers high-end efficiencies and speed. It also offers a lot of pretrained modules. It can be used for building deep neural networks, is Lua-based, and runs on Python. A lot of big companies including Facebook, IBM, and Yandex rely heavily on the PyTorch framework.
            </p>
            </div>
            <div>
          <br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
            The Bottom Line
            </div>
           
            <br/>
            <p>
            As you can see, AI is capable of greatly facilitating the workflow of different organizations. That’s 
            why adoption of this technology is moving at a breathtaking pace. Seasoned companies along with fresh-baked 
            startups are integrating Machine Learning and Deep Learning algorithms into their applications to offer advanced 
            features to end users. Healthcare, Business, Education, and Manufacturing are only some of the sectors 
            using ML applications. The continuous development of AI in general and Machine Learning in particular is 
            leading to new opportunities for business owners and new markets for services.
            </p>
            
            </div>
        
         
          <br />
        </div>
       
      </div>
      <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default Blog3;
