import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/CareerCards.css";
import Footer from "../footer";
import {Link} from 'react-router-dom'

class SalesDirector extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <div>
        <div className="career-details-top">
          <p className="career-details-tophead" align="center">
            Job Details
          </p>
        </div>
        <div className="details">
          <div className="career-details">
            <div>
              <br />
              <br />
              <div
                style={{
                  color: "#202b5d",
                  fontWeight: "600",
                  fontSize: "25px",
                  textAlign: "left",
                }}
              >
                (Senior) Regional Sales Director
              </div>
              <br />

              <p>
                While Travail has had incredible organic growth over the last
                few years the company also has made targeted acquisitions that
                add critical capabilities to the portfolio including Natural
                Language Processing, Chatbots, Machine Learning, Social and
                Messaging Transformation. At Travail, we are creating a global
                workplace that enables everyone to find their true potential,
                purpose and passion, irrespective of their background, gender,
                race, sexual orientation, religion or ethnicity. We are
                committed to providing equal opportunity for all and believe
                that diversity in the workplace creates a more vibrant, richer
                work environment that advances the goals of our employees, our
                communities and our business.
              </p>
            </div>
            <div>
              <b>About the role :</b>
            </div>
            <div style={{ width: "90%", marginLeft: "30px" }}>
              <ul>
                <li>
                  Partner with internal stakeholders, establish strong working
                  relationships, and secure internal alignment for the overall
                  go-to-market strategy for the region
                </li>
                <li>
                  Own and execute the overall go-to-market strategy and be
                  responsible for delivering on important KPIs like hiring
                  targets, quarterly revenue, and margin targets
                </li>
                <li>
                  Further, establish and develop relationships with relevant
                  industry CxOs and other key executive decision-makers in the
                  region.
                </li>
                <li>
                  Shape the future of our Sales organization in south India
                  strengthening, training, and motivating a high performing
                  sales team
                </li>
                <li>
                  Leverage data to forecast for the territory and to develop
                  actionable insights for new initiatives
                </li>
              </ul>
            </div>

            <div>
              <b>Qualifications:</b>
            </div>
            <div style={{ width: "90%", marginLeft: "30px" }}>
              <ul>
                <li>Must possess good communication skills.</li>
                <li>Must be innovative and fast in thinking.</li>
                <li>Knowledge in IT field is highly recommended.</li>
                <li>
                  Good organizational and negotiation are valuable skills
                  needed.
                </li>
              </ul>
            </div>
            <div>
              <b>Responsibilities:</b>
            </div>
            <div style={{ width: "90%", marginLeft: "30px" }}>
              <ul>
                <li>
                  Responsible for email marketing solution and seeking
                  opportunities with associations, colleges, and universities.
                </li>
                <li>Make initial contacts and appointments with</li>
                <li>Streaming live and web-based presentations.</li>
                <li>
                  Required to offer products and services to qualified clients
                  through demos.
                </li>
                <li>Handling sales and prospecting.</li>
                <li>Make offers at Tradeshows and conferences.</li>
                <li>Represent client professionally.</li>
                <li>
                  Required to build and document sales activities in company CRM
                  software and document activities.
                </li>
                <li>Plan business development team meetings.</li>
                <li>Organize educational meetings on product development.</li>
                <li>
                  Systemize with the management team on effective sales
                  presentation.
                </li>
                <li>Handling customer presentations, quotes, RFIs and RFPs.</li>
                <li>Handling crucial relationships with clients.</li>
                <li>
                  Required to investigate clients’ specific needs and
                  organizational requirements.
                </li>
                <li>
                  Responsible for applying the information gathered from
                  investigation and make use of already gained product knowledge
                  to meet the needs of the clients.
                </li>
                <li>
                  Required to develop core knowledge of the specifications and
                  features of the systems of the employing organization to
                  ensure the delivery of quality services to clients.
                </li>
                <li>
                  Establishment of networks and developing new business
                  techniques.
                </li>
                <li>Checking on clients and their welfare.</li>
                <li>
                  Required to prepare and design presentations for customers.
                </li>
                <li>
                  Make provision of technical support to clients, install
                  software programs and set up networks.
                </li>
                <li>
                  Required to create technical reports, proposals, and tenders.
                </li>
              </ul>
            </div>
            <br />
            <div>
              {" "}
              <Link to="/apply-jobs" style={{textDecoration:'none'}}>
            <Button
              className="apply-now"
              style={{
                height: "50px",
                fontWeight: "700",
              }}
            >
              APPLY NOW
            </Button>{" "}
            </Link>
            </div>
            <br />
          </div>
          {/* {this.state.modal && (
            <Modal isOpen={this.state.modal} size="md" centered>
              <div className="modal-title">
                {" "}
                <ModalHeader toggle={this.toggle}>
                  Join us and be a part of our great culture!
                </ModalHeader>
              </div>
              <ModalBody
                style={{
                  maxHeight: "calc(70vh - 210px)",
                  width: "95%",
                  marginLeft: "1%",
                  overflowY: "auto",
                }}
              >
                <Row style={{ margin: "0px", textAlign: "justify" }}>
                  {" "}
                  We never see resumes and degrees any papers. Kindly send us a
                  mail with your name, number and your story and our team will
                  respond to you.
                </Row>
                <br />
                <div style={{ textAlign: "center" }}>
                  <a href="https://mail.google.com">
                    balasubramaniyan@travailtechnologies.in
                  </a>
                </div>
              </ModalBody>
              <br />
              <ModalFooter>
                <Button
                  className="career-button-apply"
                  style={{ width: "30%" }}
                  //   style={{
                  //     border: "1px solid #268da5",
                  //     color: "#268da5",
                  //     backgroundColor: "#fff",
                  //   }}
                  onClick={this.toggle}
                >
                  OK
                </Button>
              </ModalFooter>
            </Modal>
          )} */}
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default SalesDirector;
