import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/CareerCards.css";
import Footer from "../footer";
import {Link} from 'react-router-dom'

class CareerDetailsFrontend extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <div>
      <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Job Details</p>
     </div>
      <div className="details">
        <div className="career-details">
          <div>
          <br/><br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "400",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
             Frontend Developer
            </div>
            <br />

            <p>
              While Travail has had incredible organic growth over the last few
              years the company also has made targeted acquisitions that add
              critical capabilities to the portfolio including Natural Language
              Processing, Chatbots, Machine Learning, Social and Messaging
              Transformation. At Travail, we are creating a global workplace
              that enables everyone to find their true potential, purpose and
              passion, irrespective of their background, gender, race, sexual
              orientation, religion or ethnicity. We are committed to providing
              equal opportunity for all and believe that diversity in the
              workplace creates a more vibrant, richer work environment that
              advances the goals of our employees, our communities and our
              business.
            </p>
          </div>
          <div>
            <b>Overview of the role :</b>
            <p>
              Front-end engineers at Travail are responsible for implementing
              many of the client-side systems/code that generate the UIs our
              end-users will interact with. They work closely with the backend
              and platform engineers, architects, designers, and product
              managers to develop scalable and maintainable UI
              systems/features/components.
            </p>
          </div>

          <div>
            <b>Responsibilities:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>A minimum of 3 years of relevant professional experience</li>
              <li>
                {" "}
                Planning, estimation, requirement analysis, the layout design of
                the assigned project, and other ongoing projects in a team with
                the Project Manager / Developer / Designer.
              </li>
              <li>
                {" "}
                Lead a team of developers (Front-End / UI) as well as
                contributing to a hands-on front-end developer
              </li>
              <li>
                {" "}
                Work closely with design, product management, and development
                teams to create elegant, usable, responsive, and interactive
                interfaces.
              </li>
              <li>
                {" "}
                Maintaining and auditing code quality as per set guidelines and
                standards in assigned projects.
              </li>
              <li>
                {" "}
                Testing of completed tasks in ongoing projects in a team before
                delivering it to QA.
              </li>
              <li> Focusing on the team to ensure on-time delivery.</li>
              <li> Training and mentoring of team members.</li>
              <li> People management & Technical management</li>
              <li>
                {" "}
                Implement and support compliance of self and team to travail
                compliance and information security processes.
              </li>
            </ul>
          </div>
          <div>
            <b>Technical Skills:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li> Solid knowledge of HTML, CSS, SASS.</li>
              <li> Excellent knowledge of Javascript, Jquery</li>
              <li> Excellent Knowledge of Javascript</li>
              <li> MVC architecture and OOP programming style.</li>
              <li>
                Ability to pick and use Javascript Frameworks - React / Angular
                / EmberJs / NodeJs knowledge of version control systems like
                GIT.
              </li>
              <li> Excellent communication skills</li>
              <li> Problem-solving and decision-making skills</li>
              <li>Teamwork and Leadership skills</li>
            </ul>
          </div>
          <div>
            {" "}
            <Link to="/apply-jobs" style={{textDecoration:'none'}}>
            <Button
              className="apply-now"
              style={{
                height: "50px",
                fontWeight: "700",
              }}
            >
              APPLY NOW
            </Button>{" "}
            </Link>
          </div><br />
        </div>
        {/* {this.state.modal && (
          <Modal isOpen={this.state.modal} size="md" centered>
            <div className="modal-title">
              {" "}
              <ModalHeader toggle={this.toggle}>
                Join us and be a part of our great culture!
              </ModalHeader>
            </div>
            <ModalBody
              style={{
                maxHeight: "calc(70vh - 210px)",
                width: "95%",
                marginLeft: "1%",
                overflowY: "auto",
              }}
            >
              <Row style={{ margin: "0px", textAlign: "justify" }}>
                {" "}
                We never see resumes and degrees any papers. Kindly send us a
                mail with your name, number and your story and our team will
                respond to you.
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                <a href="https://mail.google.com">
                  balasubramaniyan@travailtechnologies.in
                </a>
              </div>
            </ModalBody>
            <br />
            <ModalFooter>
              <Button
                className="career-button-apply"
                style={{ width: "30%" }}
                //   style={{
                //     border: "1px solid #268da5",
                //     color: "#268da5",
                //     backgroundColor: "#fff",
                //   }}
                onClick={this.toggle}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        )} */}
      </div>
      <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default CareerDetailsFrontend;
