import React from 'react';
import img from '../images/Rock (1).jpg';
// import img from '../images/rock4.jpg';
import "../Styles/quote.css"
import "../Styles/card.css"

import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import LightSpeed from 'react-reveal/LightSpeed';
import Navbar from "./navbar";
import { Col, Row, Container } from 'reactstrap';

class Quote extends React.Component {
    render() {
        return (
            <div id="home">
                <Navbar />
                <div className="content">
                    {/* 
                    <Row style={{ margin: '0px' }}>
                        <Col md="6" xs="12">
                            <Fade bottom>
                                <div className="quote-align">
                                    <h1 className="quote" style={{ paddingTop: '15%' }}>Way to lauch a great products for your bussiness.</h1>
                                     <h5 className="quote-desc" style={{ color: '#202b5d', paddingBottom: '13%' }}>Don't waste another minute concentrate on your sales!</h5>
                                </div>
                            </Fade>
                        </Col>
                        <Bounce right>
                            <img src={img} className="pic" />
                        </Bounce>
                    </Row> */}
                    <Container>
                        <Row style={{
                            margin: '0px'
                        }}>
                            <Col md={6} xs={12} lg={6}>
                                <Fade bottom>
                                    {/* <div className="quote-align">
                            <h1 className="quote" style={{ paddingTop: '7%' }}>Way to lauch a great products for your bussiness.</h1>
                            <h5 className="quote-desc" style={{ color: '#202b5d', paddingBottom: '8%' }}>Don't waste another minute concentrate on your sales!</h5>
                        </div> */}

                                    <div className="quote-align">
                                        <h1 className="quote" >Launch your <br /> Products and your Brand</h1>
                                        <h5 className="quote-desc" style={{ color: '#202b5d', paddingBottom: '5%' }}>We do all at once</h5><br />
                                        <a data-text="Get Started" className="quo" style={{ alignSelf: 'center' }} href="#contact">Get Started</a>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6} xs={12} lg={6}>
                                <Bounce>
                                    <img src={img} className='pic' />
                                </Bounce>
                            </Col>
                        </Row>
                    </Container>
                </div>   
            </div>
        );
    }
}
export default Quote;
