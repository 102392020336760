import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/CareerCards.css";
import Footer from "../footer";
import {Link} from 'react-router-dom'

class DigitalMarketing extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (<div>
      <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Job Details</p>
     </div>
      <div className="details">
        <div className="career-details">
          <div>
          <br/><br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
              Digital Marketing Associate
            </div>
            <br />

            <p>
              While Travail has had incredible organic growth over the last few
              years the company also has made targeted acquisitions that add
              critical capabilities to the portfolio including Natural Language
              Processing, Chatbots, Machine Learning, Social and Messaging
              Transformation. At Travail, we are creating a global workplace
              that enables everyone to find their true potential, purpose and
              passion, irrespective of their background, gender, race, sexual
              orientation, religion or ethnicity. We are committed to providing
              equal opportunity for all and believe that diversity in the
              workplace creates a more vibrant, richer work environment that
              advances the goals of our employees, our communities and our
              business.
            </p>
          </div>
          <div>
            <b>About the Role:</b>
            <p>
            The Copywriter should compose longer-form content in clear, concise language that’s conversational enough to read aloud. Also able to develop demand-gen campaigns, blog posts, website copy, banners, emails, social, and sharp, smart headlines that put a ‘fresh’ twist to our products. Because any campaign is as good as the creative. 
            You’ll craft compelling original content. You’ll refine others’ content and make it better, clearer, more conversational, and more concise. You’ll be creating copy for ads connected to advertising campaigns that drive engagement and, ultimately, revenue. You should be able to write long-form copy and—this is critical—translate business jargon into approachable, conversational English that can explain what we do in line with the tone of Travail. 
            You’ll bring a fresh perspective to how we talk to customers about Travail products and platforms. 
            </p>
            <div> <p>The stakeholders for this role are: </p>
            <div style={{ width: "90%", marginLeft: "30px" }}>
             <ul>
               <li>Motion and Graphic designers</li>
               <li>Ad Campaign Managers</li>
               <li>SEO and Content writers</li>
               <li>Bloggers</li>
             </ul>
             </div>
            </div>
          </div>

          <div>
            <b>Responsibilities:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
           <li> Write engaging copy in a conversational, customer-oriented style.</li>
           <li>Master Travail’ brand voice and tone</li>
           <li>Understand advertising craft, and write sharp, smart headlines.</li>
           <li>Monitor current competitors, industry trends, and technology</li>
           <li>Present and sell work. Provide a point of view and express it well</li>
            </ul>
          </div>
          <div>
            <b>Requirements:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>
              B.A. in English, Journalism, Communications, or related field </li>
              <li>1-3 years of experience writing marketing copy. Tech experience helps but isn’t required. </li>
              <li>Experience writing for B2B customers </li>
              <li>A reasonable understanding of technology and the cloud </li>
              <li>Knowledge of proper spelling, grammar, and punctuation </li>
              <li>Flexibility and patience in the face of changing methods and objectives </li>
              <li>Strong presentation, communication, and interpersonal skills </li>
              <li>An optimistic attitude, infectious positivity.
              </li>
            </ul>
          </div>
          <br />
          <div>
            {" "}
            <Link to="/apply-jobs" style={{textDecoration:'none'}}>
            <Button
              className="apply-now"
              style={{
                height: "50px",
                fontWeight: "700",
              }}
            >
              APPLY NOW
            </Button>{" "}
            </Link>
          </div>
          <br />
        </div>
        {/* {this.state.modal && (
          <Modal isOpen={this.state.modal} size="md" centered>
            <div className="modal-title">
              {" "}
              <ModalHeader toggle={this.toggle}>
                Join us and be a part of our great culture!
              </ModalHeader>
            </div>
            <ModalBody
              style={{
                maxHeight: "calc(70vh - 210px)",
                width: "95%",
                marginLeft: "1%",
                overflowY: "auto",
              }}
            >
              <Row style={{ margin: "0px", textAlign: "justify" }}>
                {" "}
                We never see resumes and degrees any papers. Kindly send us a
                mail with your name, number and your story and our team will
                respond to you.
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                <a href="https://mail.google.com">
                  balasubramaniyan@travailtechnologies.in
                </a>
              </div>
            </ModalBody>
            <br />
            <ModalFooter>
              <Button
                className="career-button-apply"
                style={{ width: "30%" }}
                //   style={{
                //     border: "1px solid #268da5",
                //     color: "#268da5",
                //     backgroundColor: "#fff",
                //   }}
                onClick={this.toggle}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        )} */}
      </div>
      <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default DigitalMarketing;
