import React from "react";
import doll1 from "../images/doll1.jpeg";
import doll2 from "../images/doll2.png";
import doll3 from "../images/doll3.png";
import doll4 from "../images/doll4.png";
import doll5 from "../images/doll5.png";
import doll6 from "../images/doll6.jpg";
import com from "../images/com.png";
import img from "../images/artificial.png";
import btn from "../images/btn.png";
import "../Styles/product.css";
import Scrollchor from "react-scrollchor";
import Fade from "react-reveal/Fade";
import Bounce from "react-reveal/Bounce";
import LightSpeed from "react-reveal/LightSpeed";
import Navbar from "./navbar";
import { Col, Row, Container } from "reactstrap";
import RoutingNavbar from "./RoutingNavbar";
import Footer from "./footer";

class Product extends React.Component {
  render() {
    return (
      <div>
        <RoutingNavbar />
        <br />
        <br /> <br /> <br />
        <br />
        <div>
          <div className="product-page-intro">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-7">
                  <div className="text-container">
                    {/* <h4 className="title--h5">Products</h4> */}
                    <h1 className="title--h2">
                      Check out our
                      <br />
                      <span className="special-text">Valuable Products.</span>
                    </h1>
                    <br />
                    <p className="pclass">
                      We also offer custom AI Infused Software Application
                      Solutions and Mobile Application Solutions that will turn
                      your business concept into a viable Software Products.
                    </p>
                    <a
                      class="gray-icon-button mt--20 mb-6"
                      style={{ textDecoration: "none" }}
                      href="/#contact"
                    >
                      Contact Us
                      <img src={btn} />
                    </a>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="image-container">
                    <img src={com} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="product-section">
          <div className="inner-container">
            <div className="text-container">
              <h5 className="title--h5">View All our Products</h5>
            </div>
            <div className="products">
              <div className="product">
                <div className="row">
                  <div className="col-md-4">
                    <div className="image-container">
                      <img src={doll1} className="imgofdoll" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="text-container">
                      <div className="product-head">
                        <div className="product-name">
                          <h4 className="title--h4">
                            <span className="special-text">
                              Digital Signage Solution
                            </span>
                          </h4>
                          <h3 className="title--h3">
                            Elevate Your Visual Communication
                          </h3>
                        </div>
                      </div>
                      <div className="product-details">
                        Our Digital Signage Solution offers a seamless and
                        versatile platform accessible to anyone with a smart TV
                        and our user-friendly mobile application. Empowering
                        businesses, organizations, and individuals, our solution
                        enables efficient management of multiple TVs with
                        various contents. With our cutting-edge software, users
                        can effortlessly push content to specific TVs, be it
                        images, videos, or slider images, ensuring dynamic
                        visual communication. Flexibility is at your fingertips
                        as you set layouts for each device, from single layouts
                        to dividing screens into up to 4 sections. Embrace the
                        power of impactful visual displays with our Digital
                        Signage Solution. Revolutionize your communication
                        strategy, engage your audience, and leave a last- ing
                        impression with compelling and interactive content.
                        Partner with us to showcase your brand, products, and
                        messages in a way that captivates and influences your
                        audience like never before.
                      </div>

                      <div className="cta-buttons  ">
                        <div className="know-more cta-btn">
                          <a
                            className="white-icon-button"
                            href="/#contact"
                            style={{ textDecoration: "none" }}
                          >
                            Request Demo
                            <img src={btn} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="products">
              <div className="product">
                <div className="row">
                  <div className="col-md-4">
                    <div className="image-container">
                      <img src={doll2} className="imgofdoll" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="text-container">
                      <div className="product-head">
                        <div className="product-name">
                          <h4 className="title--h4">
                            <span className="special-text">
                              Employee Management Application
                            </span>
                          </h4>
                          <h3 className="title--h3">
                            Empowering Efficient Workforce Operations
                          </h3>
                        </div>
                      </div>
                      <div className="product-details">
                        Our Employee Management Application is a versatile
                        solution catering to diverse industries and work sites,
                        efficiently managing multiple workers, projects,
                        attendance, and task assignments. Designed to streamline
                        workforce operations, it simplifies attendance tracking
                        for workers across various projects and sites,
                        facilitating accurate payroll calculations based on
                        attendance records. With seamless task management
                        features, our application empowers supervisors to assign
                        and verify tasks, ensuring optimum productivity and
                        accountability. Unlock valuable insights through
                        comprehensive reports, providing data analysis for
                        better decision-making. At Travail Technologies, we take
                        pride in delivering a user-friendly and highly adaptable
                        application that seamlessly integrates into your
                        organization's workflow. Experience enhanced efficiency,
                        improved project management, and streamlined workforce
                        operations with our Employee Management Application.
                        Partner with us to transform your workplace and elevate
                        your business to new heights of success.
                      </div>

                      <div className="cta-buttons  ">
                        <div className="know-more cta-btn">
                          <a
                            className="white-icon-button"
                            href="/#contact"
                            style={{ textDecoration: "none" }}
                          >
                            Request Demo
                            <img src={btn} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="products">
              <div className="product">
                <div className="row">
                  <div className="col-md-4">
                    <div className="image-container">
                      <img src={doll3} className="imgofdoll" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="text-container">
                      <div className="product-head">
                        <div className="product-name">
                          <h4 className="title--h4">
                            <span className="special-text">
                              Driving School Management System
                            </span>
                          </h4>
                          <h3 className="title--h3">
                            Streamlining Your School with Ease
                          </h3>
                        </div>
                      </div>
                      <div className="product-details">
                        Introducing our exclusive Driving School Management
                        System, a comprehensive solution designed to simplify
                        the complexities of running a student-friendly driving
                        school. Streamline communication, class schedules,
                        student, instructor, and staff management, as well as
                        student data within your institute. With our
                        user-friendly software, managing your school becomes an
                        impressive and efficient experience. Embrace automation
                        with automated email and text reminders, reducing up to
                        80% of phone calls, saving precious time and energy. At
                        Travail Technologies, we take pride in providing driving
                        schools with a seamless and intuitive platform, ensuring
                        smooth operations and enhanced productivity. Experience
                        the power of simplicity and efficiency with our Driving
                        School Management System, allowing you to focus on your
                        core mission of providing quality driving education.
                        Partner with us to elevate your driving school and
                        deliver exceptional services to your students and staff.
                      </div>

                      <div className="cta-buttons  ">
                        <div className="know-more cta-btn">
                          <a
                            className="white-icon-button"
                            href="/#contact"
                            style={{ textDecoration: "none" }}
                          >
                            Request Demo
                            <img src={btn} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="products">
              <div className="product">
                <div className="row">
                  <div className="col-md-4">
                    <div className="image-container">
                      <img src={doll4} className="imgofdoll" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="text-container">
                      <div className="product-head">
                        <div className="product-name">
                          <h4 className="title--h4">
                            <span className="special-text">
                              E-Commerce Solutions
                            </span>
                          </h4>
                          <h3 className="title--h3">
                            Empowering Your Online Store for a Lifetime
                          </h3>
                        </div>
                      </div>
                      <div className="product-details">
                        We offer cutting-edge E-Commerce applications and
                        websites tailored to meet your unique business needs.
                        Our ready-made solutions have been successfully imple-
                        mented across various industries, including
                        supermarkets, fish and meat delivery, stationary,
                        electronic shops, and textiles. With expertise in
                        E-Commerce development and modern technologies, we
                        provide ready-to-use E-Commerce portals and mobile apps,
                        enabling you to manage your online store effortlessly,
                        24/7. Our one-time pricing ensures a lifetime ownership
                        of the full solution, complete with login details and 6
                        months of free technical support. Embrace the future of
                        global E-Commerce trends and attain maximum ROI with our
                        comprehensive approach that addresses all critical
                        requirements of valued clients worldwide. Let us be your
                        trusted partner in revolutionizing your E-Commerce
                        venture, providing a seamless shopping experience for
                        your customers while ensuring lasting success in the
                        digital realm.
                      </div>

                      <div className="cta-buttons  ">
                        <div className="know-more cta-btn">
                          <a
                            className="white-icon-button"
                            href="/#contact"
                            style={{ textDecoration: "none" }}
                          >
                            Request Demo
                            <img src={btn} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="products">
              <div className="product">
                <div className="row">
                  <div className="col-md-4">
                    <div className="image-container">
                      <img src={doll5} className="imgofdoll" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="text-container">
                      <div className="product-head">
                        <div className="product-name">
                          <h4 className="title--h4">
                            <span className="special-text">
                              Education Consultancy Management Application
                            </span>
                          </h4>
                          <h3 className="title--h3">
                            Streamlining Your Success
                          </h3>
                        </div>
                      </div>
                      <div className="product-details">
                        Our Education Consultancy Management Application is a
                        comprehensive solution designed specifically for
                        education consultancies. Simplifying and streamlining
                        various tasks, it empowers consultancy professionals to
                        efficiently register students, manage fee collections,
                        automate invoice mailing, and evaluate executive
                        performance seamlessly. At Travail Technologies, we take
                        pride in creating a user-friendly system with multi-user
                        login capabilities, ensuring smooth collaboration and
                        enhanced productivity. With our expertise in developing
                        sophisticated reporting tools, our application provides
                        valuable insights through various reports, enabling
                        data-driven decision-making. Experience the power of
                        innovation and excellence with our Education Consultancy
                        Management Application, tailored to elevate your
                        consultancy services and propel your success in the
                        education industry. Partner with us to transform the way
                        you manage, empower your team, and achieve remarkable
                        outcomes for your students and clients alike.
                      </div>

                      <div className="cta-buttons  ">
                        <div className="know-more cta-btn">
                          <a
                            className="white-icon-button"
                            href="/#contact"
                            style={{ textDecoration: "none" }}
                          >
                            Request Demo
                            <img src={btn} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="products">
              <div className="product">
                <div className="row">
                  <div className="col-md-4">
                    <div className="image-container">
                      <img src={doll6} className="imgofdoll" />
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="text-container">
                      <div className="product-head">
                        <div className="product-name">
                          <h4 className="title--h4">
                            <span className="special-text">
                              E-Learning Applications
                            </span>
                          </h4>
                          <h3 className="title--h3">
                            Empowering Seamless Education, Anywhere
                          </h3>
                        </div>
                      </div>
                      <div className="product-details">
                        As a highly recognized mobile app development company,
                        we at Travail Technologies boast years of experience in
                        providing cutting-edge e-learning and educational mobile
                        app development solutions. Our expertise as a smart app
                        development company enables us to deliver efficient
                        e-learning app services and solutions, equipped with
                        advanced features. Our engaging and interactive
                        e-learning solutions eliminate the need for physical
                        presence, allowing students to gain knowledge from
                        anywhere. At Travail Technologies, we take pride in our
                        team of exceptional and talented mobile app developers,
                        dedicated to bringing your vision to life. With our
                        customized approach, we create e-learning applications
                        tailored to your exact requirements, facilitating
                        seamless education for learners of all ages and fields.
                        Let us empower your education venture, providing a
                        modern and innovative platform that revolutionizes the
                        learning experience for students worldwide.
                      </div>

                      <div className="cta-buttons  ">
                        <div className="know-more cta-btn">
                          <a
                            className="white-icon-button"
                            href="/#contact"
                            style={{ textDecoration: "none" }}
                          >
                            Request Demo
                            <img src={btn} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Product;
