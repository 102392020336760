import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import "../../Styles/CareerCards.css";
import Footer from "../footer";
import {Link} from 'react-router-dom'

class CareerDetailsBackend extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <div>
      <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Job Details</p>
     </div>
      <div className="details">
         
        <div className="career-details">
          <div>
          <br/><br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "600",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
              Backend Developer
            </div>
            <br />

            <p>
              While Travail has had incredible organic growth over the last few
              years the company also has made targeted acquisitions that add
              critical capabilities to the portfolio including Natural Language
              Processing, Chatbots, Machine Learning, Social and Messaging
              Transformation. At Travail, we are creating a global workplace
              that enables everyone to find their true potential, purpose and
              passion, irrespective of their background, gender, race, sexual
              orientation, religion or ethnicity. We are committed to providing
              equal opportunity for all and believe that diversity in the
              workplace creates a more vibrant, richer work environment that
              advances the goals of our employees, our communities and our
              business.
            </p>
          </div>
          <div>
            <b>Overview of the role :</b>
            <p>
              As a Lead Software Engineer, you will focus on building
              next-generation platform services for travail with your strong
              background in distributed systems and mentor your team to achieve
              this. You will have an opportunity to redefine customer
              experiences by building systems that are milli-second efficient,
              always available, and working at an internet-scale. If you are the
              kind of engineer who is passionate about building systems, have a
              good eye for analysis, and have a mind that can think outside the
              box, we want to talk to you.
            </p>
          </div>

          <div>
            <b>Responsibilities:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>
                Lead teams to deliver scalable, low latency, and cost-efficient
                solutions to different product teams.
              </li>
              <li>
                Drive solutions and implementation leveraging different open
                source distributed systems and deliver a complete product.
              </li>
              <li>
                Build innovative solutions from scratch and liaise with
                architects and engineers from other product teams to build
                solutions and drive adoption.
              </li>
              <li>
                Elicit quality attributes of the system as well as create
                criteria metrics for the product to establish the success of
                achieved metrics
              </li>
              <li>
                Implement and support compliance of self and team to travail
                compliance and information security processes.
              </li>
            </ul>
          </div>
          <div>
            <b>Requirements:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>
                A background in Engineering with sound oral and written
                communication skills
              </li>
              <li>Solid problem-solving skills</li>
              <li> Experience with hands-on programming</li>
              <li> Expertise in data structures and algorithms</li>
              <li> Extensive knowledge of Linux environments</li>
              <li> Advanced understanding of OOP</li>
              <li> Experience building large-scale performance services</li>
              <li>
                Experience troubleshooting in a SaaS environment with an
                assertive deployment schedule
              </li>
            </ul>
          </div>
          <br />
          <div>
            {" "}
            <Link to="/apply-jobs" style={{textDecoration:'none'}}>
            <Button
              className="apply-now"
              style={{
                height: "50px",
                fontWeight: "700",
              }}
            >
              APPLY NOW
            </Button>{" "}
            </Link>
          </div>
          <br />
        </div>
        {/* {this.state.modal && (
          <Modal isOpen={this.state.modal} size="md" centered>
            <div className="modal-title">
              {" "}
              <ModalHeader toggle={this.toggle}>
                Join us and be a part of our great culture!
              </ModalHeader>
            </div>
            <ModalBody
              style={{
                maxHeight: "calc(70vh - 210px)",
                width: "95%",
                marginLeft: "1%",
                overflowY: "auto",
              }}
            >
              <Row style={{ margin: "0px", textAlign: "justify" }}>
                {" "}
                We never see resumes and degrees any papers. Kindly send us a
                mail with your name, number and your story and our team will
                respond to you.
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                <a href="https://mail.google.com">
                  balasubramaniyan@travailtechnologies.in
                </a>
              </div>
            </ModalBody>
            <br />
            <ModalFooter>
              <Button
                className="career-button-apply"
                style={{ width: "30%" }}
                //   style={{
                //     border: "1px solid #268da5",
                //     color: "#268da5",
                //     backgroundColor: "#fff",
                //   }}
                onClick={this.toggle}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        )} */}
      </div>
      <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default CareerDetailsBackend;
