import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import cust2 from "../images/clinic.png";
import cust3 from "../images/cust3.jpg";
import cust4 from "../images/pure-veg.jpg";
import { Row, Col, Container } from "react-bootstrap";
import Avatar from "react-avatar";
// import image1 from "../assets/img1.png";
import orivent from "../images/orivent.jpg";
// import OwlCarousel from "react-owl-carousel";
// Import Swiper JS
// import Swiper from "swiper/bundle";

// import image3 from "../assets/img3.jpg";
// import image4 from "../assets/img4.jpg";
// import image5 from "../assets/img5.jpg";

import image6 from "../assets/img6.jpg";
import image7 from "../assets/img7.jpg";
import image8 from "../assets/img8.jpg";
import image9 from "../assets/img9.jpg";
import image10 from "../assets/img10.jpg";
import image11 from "../assets/img11.jpg";
import image12 from "../assets/img12.jpg";
import image13 from "../assets/img14.jpg";
import image14 from "../assets/img15.png";

import eye1 from "../images/LOGO.png";
import eye2 from "../images/Craft new logo.jpg";
import eye3 from "../images/obo_health_logo.jpeg";
import eye5 from "../assets/img14.jpg";
import eye6 from "../assets/img15.png";
import eye7 from "../images/logo1.png";
import eye8 from "../images/kam.jpeg";
import eye9 from "../images/orivent.jpg";
import eye10 from "../images/saletancy_logo.jpeg";
import eye11 from "../images/Squarefoot.jpg";

// import OwlCarousel from "react-owl-carousel";
import "../Styles/clients.css";
// import $ from "jquery";
const images = [eye1, eye2, eye3,  eye5, eye6, eye7, eye8, eye9, eye10, eye11];
export class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentSlide: 0,
      currentImageIndex: 0,
    };
  }

  componentDidMount() {
    this.startAutoplay();
  }

  componentDidMount() {
    // Start the automatic movement of images
    this.interval = setInterval(() => {
      this.nextImage();
    }, 3000); // Change image every 3 seconds (adjust as needed)
  }

  componentWillUnmount() {
    // Clear the interval when the component is unmounted
    clearInterval(this.interval);
  }
  componentWillUnmount() {
    this.stopAutoplay();
  }

  startAutoplay = () => {
    this.autoplayInterval = setInterval(() => {
      this.setState((prevState) => ({
        currentSlide: (prevState.currentSlide + 1) % 14, // Adjust the total number of images
      }));
    }, 5000); // Adjust the autoplay interval as needed (3000 milliseconds = 3 seconds)
  };

  stopAutoplay = () => {
    clearInterval(this.autoplayInterval);
  };
  nextImage() {
    const { currentImageIndex } = this.state;
    const nextIndex = (currentImageIndex + 1) % images.length;
    this.setState({ currentImageIndex: nextIndex });
  }
  render() {
    const totalImages = 14;
    const { currentImageIndex } = this.state; // Adjust based on the number of images you have
    const images = [eye1, eye2, eye3,  eye5, eye6, eye7, eye8, eye9, eye10, eye11];

    return (
      <div>
        <Container>
          <Row style={{ margin: "0px" }}>
            {/* <Col md={1}/> */}
            <Col md={5} xs={12} sm={12}>
              <br />
              <br />
              <p className="c-content">Happy Customers!</p>
              <p className="c-heading">
                We give our best to make
                <br />
                you happy!
              </p>
              <p className="c-content">
                Hear what our customers says about us!
              </p>
            </Col>
            <Col md={5} xs={12} sm={12}>
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showStatus={false}
                showArrows={false}
              >
                <div className="h-carousel">
                  <Avatar
                    src={orivent}
                    round={true}
                    size={80}
                    className="c-img"
                  />
                  {/* <h3 className='c-heading'>Dave</h3> */}
                  <p className="c-content" style={{ paddingTop: "3%" }}>
                    "Despite increased accessibility to customer service by
                    technological advancements like chatbots,remote
                    troubleshooting is an area that remains clunky at
                    best.Travail VR and AR has the potential to be a game
                    changer in our startup."
                  </p>
                </div>
                <div className="h-carousel">
                  <Avatar
                    src={cust3}
                    round={true}
                    size={80}
                    className="c-img"
                  />

                  {/* <h3 className='c-heading'>Albert</h3> */}
                  <p className="c-content" style={{ paddingTop: "3%" }}>
                    "We like travail because it is a highly adaptable solution
                    in Ecommerce Development with a lot of metrics that allows
                    us to track and follow up on our customers and agents
                    efficiently."
                  </p>
                </div>
                <div className="h-carousel">
                  <Avatar
                    src={cust4}
                    round={true}
                    size={80}
                    className="c-img"
                  />

                  {/* <h3 className='c-heading'>Albert</h3> */}
                  <p className="c-content" style={{ paddingTop: "3%" }}>
                    "Fresh and simple.That is what we are looking for in today’s
                    hospital management software world where simplicity is
                    overlooked and not found in other tools.We are not used to
                    working with companies like this - young,ambitious,and
                    energetic.It feels nice."
                  </p>
                </div>
              </Carousel>
              <br />
            </Col>
          </Row>
        </Container>

        <Row>
          <p
            className="h-heading4"
            style={{
              color: "#202B5D",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Our Clients
          </p>
          <div className="main_icon">
            {/* <OwlCarousel items={6} loop nav>
              <div>
                <img
                  src={cust2}
                  alt="image"
                  className="Sub_icons col-lg-2 col-md-4 col-12"
                />
              </div>
              <div>
                <img
                  src={cust3}
                  alt="image"
                  className="Sub_icons col-lg-2 col-md-4 col-12"
                />
              </div>
              <div>
                <img
                  src={cust4}
                  alt="image"
                  className="Sub_icons col-lg-2 col-md-4 col-12"
                />
              </div>
            </OwlCarousel> */}
            {/* <OwlCarousel items={6} loop nav autoplay autoplayTimeout={2000}>
              <div>
                <img
                  src={image1}
                  alt="image"
                  className="Sub_icons col-lg-2 col-md-4 col-12"
                />
              </div>
              <div>
                <img
                  src={image3}
                  alt="image"
                  className="Sub_icons col-lg-2 col-md-4 col-12"
                />
              </div>
              <div>
                <img
                  src={image4}
                  alt="image"
                  className="Sub_icons col-lg-2 col-md-4 col-12"
                />
              </div>

              <div>
                <img
                  src={image5}
                  alt="image"
                  className="Sub_icons col-lg-2 col-md-4 col-12"
                />
              </div>
              <div>
                <img
                  src={image6}
                  alt="image"
                  className="Sub_icons col-lg-2 col-md-4 col-12"
                />
              </div>
              <div>
                <img
                  src={image7}
                  alt="image"
                  className="Sub_icons1 col-lg-2 col-md-4 col-12"
                />
              </div>
            </OwlCarousel> */}
            <Carousel
              autoPlay
              infiniteLoop
              showStatus={false}
              showThumbs={false}
              selectedItem={this.state.currentSlide}
              onChange={(index) => this.setState({ currentSlide: index })}
            >
              {images.map((image, index) => (
                <div key={index} className="mainnew">
                  <img src={image} className="centered-image" />
                </div>
              ))}
            </Carousel>
          </div>
        </Row>
      </div>
    );
  }
}

export default Client;
