import React, { Component } from 'react'
import {Row,Col,Card, Container} from 'react-bootstrap'
import {BsFillArrowUpRightSquareFill} from 'react-icons/bs'
import {MdUpcoming} from 'react-icons/md'
import '../Styles/r&d.css'
import rd1 from '../images/machineL.png'
import rd2 from '../images/deepLearning.png'
import rd3 from '../images/artificial.png'
// import video2 from '../images/denial.mp4'
import {BiVideo} from 'react-icons/bi'
import HoverVideoPlayer from 'react-hover-video-player'
import {Link} from 'react-router-dom'
import rb1 from '../images/rb1.png'
import rdflownew from '../images/rdflownew1.png'
import robot from '../images/robot.png'
import RoutingNavbar from "./RoutingNavbar";
import Footer from './footer';
import img4 from "../images/e-commerce.png";
import processor from "../images/processor.png";
import business from "../images/business.png";
import coding from "../images/coding.png";
import chat from "../images/chat.png";
import voice  from "../images/voiceassistant.png";
import vision  from "../images/computer.png";

export class RD extends Component {
  render() {
    return (
        <div>
        <div> <RoutingNavbar /></div>
             <br />
          <br />{" "}
          <div className="career-top">
            <p className="career-tophead" align="center">
             Want a Big Digital Impact ?
            </p>
            <br/>
            <br/>
          </div>
            <Row style={{margin:'0px'}}>
              <Col md="1"/>
              <Col > 
              {/* md="8" */}
                <h4 className='h-heading'>Creating the next big thing</h4>
                <p className='h-content'>
                AI is soon going to be invaluable to all business applications in your modern software world, So Travail R&D team enhance your software development 
                process by incorporating it into as many sections as possible from design phase.
                </p>
               <Link to='/E-Commerce' className='link'><p className='apply-btn'>Get Demo</p></Link> 
              </Col>
              <Col md="3" style={{paddingTop:"2%"}}>
              <img src={robot} className='rd-img top-img' />
              </Col>
          </Row>
          <br/><br/>
          <Row style={{margin:'0px'}}>
              {/* <Col md="1"/> */}
              <Col md={10}>
              <h4 className='h-heading'>Innovation at each stage</h4>
                <p className='h-content'>
                   Travail Success is based on our ability to create innovation in every stage of development process.We invested in 60+ research scholars, and a range of emerging technologies and breakthroughs,which we believe will 
                   create significant opportunities to deliver value to our customers and growth for the company.In this fast-growing world, exploring and experimenting with disruptive technologies is 
                   the key to managing technology risk and hence we maintain our long-term committment to research and development across a wide spectrum of technologies,tools and platforms,business problems.
                </p>
              </Col>
              {/* <Col md="3" xs={12} sm={12}>
                  <img src="https://revcycleintelligence.com/images/site/features/2018-11-29_Healthcare_workforce_managment.jpg" className='right-img'/>
              </Col> */}
              {/* <Col md="1"></Col> */}
          </Row>
          <br/><br/>
         
          {/* <h4 className='h-heading' align='center'>Research Paper</h4><br/> */}
          {/* <Container>
          <Row style={{margin:'0px'}} >
            <Col md="4">
            <Card className='h-cards' style={{width:'24rem'}}>
           <Card.Img variant="top" src="https://tateeda.com/wp-content/uploads/2021/08/image-3.5.png"  />
           <Card.Body>
               <Card.Title>Title</Card.Title>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
           </Card.Body>
           </Card>
           </Col>
           <Col md="4">
            <Card className='h-cards' style={{width:'24rem'}}>
           <Card.Img variant="top" src="https://tateeda.com/wp-content/uploads/2021/08/image-3.5.png" />
           <Card.Body>
               <Card.Title>Title</Card.Title>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
           </Card.Body>
           </Card>
           </Col>
           <Col md="4">
            <Card className='h-cards' style={{width:'24rem'}}>
           <Card.Img variant="top" src="https://tateeda.com/wp-content/uploads/2021/08/image-3.5.png" />
           <Card.Body>
               <Card.Title>Title</Card.Title>
               <p className='card-learnmore'>Learn More&nbsp;&nbsp;<BsFillArrowUpRightSquareFill className='learnmore-icon'/></p>
           </Card.Body>
           </Card>
           </Col>
          
        </Row>
        </Container> */}
        <h4 className='h-heading' align='center'>Our Research Process</h4><br/>
         <Container>
        {/* <Row style={{margin:'0px'}}> */}
        <Col md="2"/>
          <Col style={{display:"flex",justifyContent:"center"}}>
            <img src={rdflownew} style={{width:"100%"}}/>
          </Col>
        <Col md="3"/>
        {/* </Row> */}
        </Container><br/>
      
        <Col><h4 className='h-heading' align='center'>Our Research Area</h4><br></br></Col>
        <Container>
        {/* <Row style={{margin:'0px'}}> */}
          {/* <Col md="12" xs={12} sm={12}>
            <img src={rb1} className='rd-img'/>
            </Col> */}
          {/* </Row> */}
        </Container><br/>
        <div className="rd-conatiner">
              <div >
              <Row>

              <Col >
              <img src={coding} alt="icon" width="55" height="55" style={{marginRight:"79%"}}/>
              </Col>
              </Row>  
                
                <Row>

                  <Col className="rdcard-head">
                    {" "}
                  Machine Learning
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="randd-content" >
                  We implement machine learning concepts to build AI solutions that can
                  gather unstructed data and convert it into actionable insights to drive
                  business growth.
                  </Col>
                </Row>
                 
              </div>
              <div >
              <Row>

              <Col >
              <img src={business} alt="icon" width="75" height="75" style={{marginRight:"79%"}}/>
              </Col>
              </Row>  
                
                <Row>

                  <Col className="rdcard-head">
                    {" "}
                  Business Intelligence
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="randd-content" >
                   Our AI engineers build strategies to execute optimization,customer analysis forecasting and performance analysis
                  </Col>
                </Row>
                 
              </div>

              <div >
              <Row>

              <Col >
              <img src={processor} alt="icon" width="75" height="75" style={{marginRight:"79%"}}/>
              </Col>
              </Row>  
                
                <Row>

                  <Col className="rdcard-head">
                    {" "}
                 Natural Language Processing (NLP)
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="randd-content" >
                  With NLP and NLU, our AI developers help organizations to analyze
                  customer feedback and sentiments to improve customer engagement
                  and increase business revenue.
                  </Col>
                </Row>
                 
              </div>
              <div >
              <Row>

              <Col >
              <img src={vision} alt="icon" width="55" height="55" style={{marginRight:"79%"}}/>
              </Col>
              </Row>  
                
                <Row>

                  <Col className="rdcard-head">
                    {" "}
                 Computer Vision
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="randd-content" >
                 Our AI developers are experienced in developing solutions for the
                 recognition of objects and classification of images using Amazon
                 recognition and Deep Learning-based Visual Search.
                  </Col>
                </Row>
                 
              </div>

              <div >
              <Row>

              <Col >
              <img src={voice} alt="icon" width="50" height="50" style={{marginRight:"79%"}}/>
              </Col>
              </Row>  
                
                <Row>

                  <Col className="rdcard-head">
                    {" "}
                 Voice Assistant
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="randd-content" >
                 Our AI developers also build voice assistants using NLP and voice
                 recognition that enhance brand awareness and boost productivity
                 through voice search.
                  </Col>
                </Row>
                 
              </div>

              <div >
              <Row>

              <Col >
              <img src={chat} alt="icon" width="70" height="70" style={{marginRight:"79%"}}/>
              </Col>
              </Row>  
                
                <Row>

                  <Col className="rdcard-head">
                    {" "}
                 ChatBot Development
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="randd-content" >
                 Our AI development team develops chatbots that facilitate personalize
                 interaction to increase loyalty and customer retention.
                  </Col>
                </Row>
                 
              </div>
              {/* <div className="careercard-2">
              <Row>
                  <Col className="rdcard-head">
                    {" "}
                    Frontend Developer
                  </Col>
                </Row>
                <Row>
                  <Col className="rdcard-content" >
                    {" "}
                    CHENNAI&nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp;ENGINEERING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="rd-content" >
                    Software Developer
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsFrontend" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                   
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
              <div className="careercard-3">
              <Row>
                  <Col className="rdcard-head">
                    {" "}
                    Software Development Engineer in Test
                  </Col>
                </Row>
                <Row>
                  <Col className="rdcard-content" >
                    {" "}
                    CHENNAI &nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp; ENGINEERING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="rd-content" >
                     Software Tester
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsTesting" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                   
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
              <div className="careercard-4">
              <Row>
                  <Col className="rdcard-head">
                    {" "}
                    Senior Data Scientist
                  </Col>
                </Row>
                <Row>
                  <Col className="rdcard-content" >
                    {" "}
                    CHENNAI &nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp; ENGINEERING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="rd-content" >
                    Data Scientist
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsDataScientist"  style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                    
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
              <div className="careercard-5">
              <Row>
                  <Col className="rdcard-head">
                    {" "}
                    Digital Marketing Associate
                  </Col>
                </Row>
                <Row>
                  <Col className="rdcard-content" >
                    {" "}
                    CHENNAI &nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp;MARKETING
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="rd-content" >
                     Marketing
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsDigitalMarketing" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                   
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div>
              <div className="careercard-6">
              <Row>
                  <Col className="rdcard-head">
                    {" "}
                    (Senior) Talent Acquisition Specialist
                  </Col>
                </Row>
                <Row>
                  <Col className="rdcard-content" >
                    {" "}
                    CHENNAI&nbsp;&nbsp; FULL TIME&nbsp;&nbsp;&nbsp;HR &
                    FACILITIES
                  </Col>
                </Row>
                <Row>
                  <Col
                  className="rd-content" >
                     Hr & facilities
                  </Col>
                </Row>
                <Row >
                <Col md="11" style={{justifyContent:'flex-start', display:'flex', paddingTop:'3%'}} >
                  <a href="/Career/CareerDetailsHR" style={{textDecoration:'none', outline:'none'}}>
                    {" "}
                   
                    {" "}
                  </a>
                  </Col>
                
                </Row>
              </div> */}
            </div>
            <h4 className='h-heading' align='center'>Research Blogs</h4><br/>
          <Container className='research-container'>
          {/* <Row style={{margin:'0px'}} > */}
            <Col md="4" xs={12} sm={12}>
            <Card className='h-cards'>
           <Card.Img variant="top" src={rd1} className='f-img' />
           <Card.Body>
               <Card.Title className='card-title'>Machine Learning</Card.Title>
               <p >Learn More&nbsp;&nbsp;<Link to="/RD/Machine-Learning-Blog"><BsFillArrowUpRightSquareFill className='learnmore-icon'/></Link></p>
           </Card.Body>
           </Card> 
            </Col>
            <Col md="4" xs={12} sm={12}>
            <Card className='h-cards'>
           <Card.Img variant="top" src={rd2} className='f-img'/>
           <Card.Body>
               <Card.Title  className='card-title'>Deep Tech</Card.Title>
               <p >Learn More&nbsp;&nbsp;<Link to="/RD/Deep-Tech-Blog"><BsFillArrowUpRightSquareFill className='learnmore-icon'/></Link></p>
           </Card.Body>
           </Card>
            </Col>
            <Col md="4" xs={12} sm={12}>
            <Card className='h-cards'>
           <Card.Img variant="top" src={rd3} className='f-img'/>
           <Card.Body>
               <Card.Title  className='card-title'>Artificial Intelligence</Card.Title>
               <p >Learn More&nbsp;&nbsp;<Link to="/RD/Artificial-Intelligence-Blog"><BsFillArrowUpRightSquareFill className='learnmore-icon'/></Link></p>
           </Card.Body>
           </Card>
           </Col>
        {/* </Row> */}
        </Container><br/>
        
          <Col><h4 className="h-heading" align="center">There is no alternative to digital transformation</h4></Col>
          <a href="/#contact"><p className='contact-us-rd' align="center">Contact Us</p></a>
      <br/>
      <div><Footer/></div>
        </div>
    )
  }
}

export default RD