import React from "react";
// import global from "../images/Contactus.jpg";
import global from "../images/contactnew.jpg";
import ph1 from "../images/ph1.png";
import ph2 from "../images/ph2.png";
import "../Styles/contact.css";
import "../Styles/card.css";
import { Col, Row } from "react-bootstrap";
import RubberBand from "react-reveal/RubberBand";
import { useForm } from "react-hook-form";
import axios from "axios";

export default function Contact() {
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = async (data) => {
    console.log(data);
    const res = await axios
      .post("http://52.66.7.123:3001/user/register", {
        name: data.name,
        emailId: data.email,
        phoneNo: data.mobile,
      })
      .then((res) => {
        console.log(res.data);
        alert("We will reach you soon...");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div id="contact">
      <br />
      <br />
      <RubberBand>
        <div className="contact">
          <h1
            className="quote"
            style={{
              color: "#202b5d",
              textAlign: "center",
              paddingBottom: "2%",
            }}
          >
            Let us help you with your new project
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row style={{ margin: "0px" }}>
              <Col md="6" lg="6" sm="12" xs="12">
                <div className="card-contact">
                  <div className="card-cont-body">
                    <h3 align="center">Write to us!</h3>
                    <br />
                    <Row style={{ margin: "0px" }}>
                      <Col md="6" xs={12} sm={6}>
                        <label style={{ fontSize: "20px" }} id="name">
                          Name <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          className="contact-input"
                          name="name"
                          type="text"
                          id="name"
                          placeholder="Your name"
                          ref={register({ required: "name is required" })}
                        />
                        {errors.name && (
                          <p style={{ color: "red" }}>{errors.name.message}</p>
                        )}
                      </Col>
                      <Col md="6" xs={12} sm={6}>
                        <label style={{ fontSize: "20px" }} id="organization">
                          Organization<sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          className="contact-input"
                          name="organization"
                          type="text"
                          id="organization"
                          placeholder="Organization Name"
                          ref={register({
                            required: "organization name is required",
                          })}
                        ></input>
                        {errors.organization && (
                          <p style={{ color: "red" }}>
                            {errors.organization.message}
                          </p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ margin: "0px" }}>
                      <Col md="12" xs={12} sm={12}>
                        <label style={{ fontSize: "20px" }} id="email">
                          E-mail Id <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          className="contact-input"
                          name="email"
                          type="email"
                          id="email"
                          placeholder="youremail@gmail.com"
                          ref={register({
                            required: "email is required",
                            pattern: {
                              value: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,
                              message: "email not valid",
                            },
                          })}
                        />
                        {errors.email && (
                          <p style={{ color: "red" }}>{errors.email.message}</p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ margin: "0px" }}>
                      <Col md="12" xs={12} sm={12}>
                        <label style={{ fontSize: "20px" }} id="mobile">
                          Mobile Number <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                          className="contact-input"
                          name="mobile"
                          type="text"
                          id="mobile"
                          placeholder="Mobile Number"
                          ref={register({
                            required: "mobile number required",
                            pattern: {
                              value: /^[0-9]{10}$/,
                              message: "Invalid Mobile No",
                            },
                          })}
                        />
                        {errors.mobile && (
                          <p style={{ color: "red" }}>
                            {errors.mobile.message}
                          </p>
                        )}
                      </Col>
                    </Row>
                    <br />
                    <Row style={{ margin: "0px" }}>
                      <Col md="12" xs={12} sm={12}>
                        <label style={{ fontSize: "20px" }} id="yourmessage">
                          Your Message <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <textarea
                          rows={4}
                          className="contact-textarea"
                          name="yourmessage"
                          type="text"
                          id="yourmessage"
                          placeholder="Your Message"
                          ref={register({
                            required: "your message is required",
                          })}
                        />
                        {errors.yourmessage && (
                          <p style={{ color: "red" }}>
                            {errors.yourmessage.message}
                          </p>
                        )}
                      </Col>
                    </Row>

                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <a href="#home">
                      <button
                        type="submit"
                        className="cont"
                        data-text="Get in touch with us"
                      ></button>
                    </a>
                  </div>
                </div>
              </Col>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  src={global}
                  alt="global"
                  width="100%"
                  height="100%"
                  className="global"
                />
                {/* <div className="addresscontainer">
                  <div className="row noGutters-All">
                    <div className="col-12 col-sm-6">
                      <div className="address-div">
                        <h5 className="title--h6 special-text uppercase-text">
                          USA
                        </h5>
                        <address className="mt--20 regular-text">
                          1008 Greek Row Dr, Arlington, Texas 76013, US
                        </address>
                        <div
                          className="icon-link mb-2"
                          style={{ textAlign: "left" }}
                        >
                          <img src={ph1} className="icon" />
                          <a href="6822477760">6822477760</a>
                        </div>
                        <div
                          className="icon-link mb-2"
                          style={{ textAlign: "left" }}
                        >
                          <img src={ph2} className="icon" />
                          <a href="info.usa@travailtechnologies.com">
                            info.usa@travailtechnologies.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="address-div">
                        <h5 className="title--h6 special-text uppercase-text">
                          Australia
                        </h5>
                        <address className="mt--20 regular-text">
                          5/22 Magnolia Drive, Brookwater,
                          <br /> QLD 4300
                        </address>
                        <div
                          className="icon-link mb-2"
                          style={{ textAlign: "left" }}
                        >
                          <img
                            src={ph1}
                            className="icon"
                            style={{ textAlign: "left" }}
                          />
                          <a href="+61481829845">+61481829845</a>
                        </div>
                        <div
                          className="icon-link mb-2"
                          style={{ textAlign: "left" }}
                        >
                          <img src={ph2} className="icon" />
                          <a href="info.au@travailtechnologies.com">
                            info.au@travailtechnologies.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="address-div">
                        <h5 className="title--h6 special-text uppercase-text">
                          India
                        </h5>
                        <address className="mt--20 regular-text">
                          P/No 179 Phase 182,Tnhb Ayyapakkam,Chennai,Tamil Nadu
                          -600077
                        </address>
                        <div
                          className="icon-link mb-2"
                          style={{ textAlign: "left" }}
                        >
                          <img src={ph1} className="icon" />
                          <a href="914435914999">914435914999</a>
                        </div>
                        <div
                          className="icon-link mb-2"
                          style={{ textAlign: "left" }}
                        >
                          <img src={ph2} className="icon" />
                          <a href=" support@travailtechnologies.com">
                            support@travailtechnologies.com
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="address-div">
                        <h5 className="title--h6 special-text uppercase-text">
                          Bahrain
                        </h5>
                        <address className="mt--20 regular-text">
                          Flat 21, Level 2, Bahrain Chamber Of Commerce Building
                          Sanabis, Manama, Kingdom Of Bahrain
                        </address>
                        <div
                          className="icon-link mb-2"
                          style={{ textAlign: "left" }}
                        >
                          <img
                            src={ph1}
                            className="icon"
                            style={{ textAlign: "left" }}
                          />
                          <a href="+97333617961">+97333617961</a>
                        </div>
                        <div
                          className="icon-link mb-2"
                          style={{ textAlign: "left" }}
                        >
                          <img src={ph2} className="icon" />
                          <a href="info.bh@travailtechnologies.com">
                            info.bh@travailtechnologies.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <Col />
            </Row>
          </form>
        </div>
      </RubberBand>
    </div>
  );
}
