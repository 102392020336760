import React, { Component } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Footer from "../footer";
import {Link} from 'react-router-dom'

class CareerDetailsTesting extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
    };
  }
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {
    return (
      <div>
      <div className='career-details-top'>
      <p className='career-details-tophead' align='center'>Job Details</p>
     </div>
      <div className="details">
        <div className="career-details">
          <div>
          <br/><br/>
            <div
              style={{
                color: "#202b5d",
                fontWeight: "400",
                fontSize: "25px",
                textAlign: "left",
              }}
            >
             Software Development Engineer in Test
            </div>
            <br />

            <p>
              While Travail has had incredible organic growth over the last few
              years the company also has made targeted acquisitions that add
              critical capabilities to the portfolio including Natural Language
              Processing, Chatbots, Machine Learning, Social and Messaging
              Transformation. At Travail, we are creating a global workplace
              that enables everyone to find their true potential, purpose and
              passion, irrespective of their background, gender, race, sexual
              orientation, religion or ethnicity. We are committed to providing
              equal opportunity for all and believe that diversity in the
              workplace creates a more vibrant, richer work environment that
              advances the goals of our employees, our communities and our
              business.
            </p>
          </div>
          <div>
            <b>About the Role:</b>
            <p>
              Involved in every phase of SDLC, Lead SETs at travail, are in
              complete ownership of the testing component of the organization’s
              cutting-edge projects. Interfacing very closely with developers,
              they provide expertise in the planning, constructing, execution,
              and successful deployment of programs and products. In the
              landscape of challenging and competitive product markets that
              demand high reliability, a Lead SET is responsible for writing and
              reviewing test cases, integrating test algorithms, automation
              scripts and uncovering potential bugs, before anyone else can.
              They also ensure the product is secure and scalable by checking
              security and performance testing and contribute to product
              continuous Integration and deployment. All of our Lead SETs are
              highly responsive, flexible, adapt to succeed, all within an open
              collaborative peer environment, solving problems in insightful
              ways. This position offers the candidate several opportunities to
              learn and test world-class B2B SaaS products that are built using
              cutting-edge technologies.
            </p>
          </div>
          <div>
            <b>About the Team:</b>
            <p>
              The Team enforces manufacturability and testability, ensuring a
              successful and robust end-product. Travail relies on this team to
              drive better software architecture and design, promote proper
              development practices, bug prevention strategies, eliminate weak
              spots, among others. This is a team that knows what is right,
              learns from each other, and is passionate about upholding
              Travail's reputation in a world-class product market.{" "}
            </p>
          </div>

          <div>
            <b>Responsibilities:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li>Lead end to end Testing and Automation Process</li>
              <li>Design and implement efficient Testing practices</li>
              <li>
                Conduct Testcase and Code reviews to ensure the quality of Test
                Cases, Test coverage, and Automation Code
              </li>
              <li>
                Participate in Planning meetings and ensure quality at all
                levels of SDLC{" "}
              </li>
              <li>Provide estimations for Manual and Automation</li>
              <li>
                Provide Testing and Automation Strategy for newly developed
                Features
              </li>
              <li>
                Identify the right tool and set up the automation framework for
                UI, API, and Microservices
              </li>
              <li>
                Increase Automation confidence by reducing random test failures
              </li>
              <li>
                Work closely with the Development team and ensures code is
                delivered with high code coverage
              </li>
              <li>
                Analyze Customer stats and implement the right tool for
                Cross-Browser / Platform Testing and Automation
              </li>
              <li>
                Responsible for QA metrics and Automation ROI and report them to
                management
              </li>
              <li>
                Identify Performance Application Performance bottlenecks and
                suggest the right tool and test strategy for Non-Functional
                Testing
              </li>
              <li>
                Guide and Mentor QA Team on the Testing and Automation Tools,
                Frameworks and Process
              </li>
              <li>
                Measure and Investigate Defect Leakage and provide preventive
                measures{" "}
              </li>
              <li>
                Identify CI / CD tools, define branching strategies, setup CI /
                CD pipeline
              </li>
              <li>
                Responsible for providing QA Tech debts and Automation Roadmaps{" "}
              </li>
              <li>
                Identify QA Risk and Mitigation Plans and ensure product
                delivery as per the release timeline
              </li>
            </ul>
          </div>
          <div>
            <b>Qualifications:</b>
          </div>
          <div style={{ width: "90%", marginLeft: "30px" }}>
            <ul>
              <li> 4-10 yrs of experience in automation testing</li>
              <li>Passionate about QA and breaking software systems</li>
              <li>
                Good knowledge of Software Testing concepts and methodology
              </li>
              <li>
                Good Understanding of Web fundamentals (MVC, HTML, JavaScript,
                CSS, Server Side Programming and Database)
              </li>
              <li>
                Good programming skills (Java / Ruby / Python / Node. js /
                JavaScript)
              </li>
              <li>
                Good knowledge of Object-Oriented Programming concepts and
                Design Patterns
              </li>
              <li>
                Hands-on Knowledge of Test Automation approaches, Frameworks and
                Tools
              </li>
              <li>
                Hands-on experience in UI, API, Cross-browser Testing Tools and
                Automation
              </li>
              <li>
                Hands-on any of performance Testing and Tools (Jmeter /
                Loadrunner / Gatling )
              </li>
              <li>
                Hands on knowledge on Continuous Integration & Continuous
                Deployment and Continuous integration tools (Jenkins / Travis/
                Teamcity)
              </li>
              <li>Good Analytical and Problem Solving Skills</li>
              <li>Good verbal and written communication skills</li>
            </ul>
          </div>
          <div>
            {" "}
            <Link to="/apply-jobs" style={{textDecoration:'none'}}>
            <Button
              className="apply-now"
              style={{
                height: "50px",
                fontWeight: "700",
              }}
            >
              APPLY NOW
            </Button>{" "}
            </Link>
          </div><br />
        </div>
        {/* {this.state.modal && (
          <Modal isOpen={this.state.modal} size="md" centered>
            <div className="modal-title">
              {" "}
              <ModalHeader toggle={this.toggle}>
                Join us and be a part of our great culture!
              </ModalHeader>
            </div>
            <ModalBody
              style={{
                maxHeight: "calc(70vh - 210px)",
                width: "95%",
                marginLeft: "1%",
                overflowY: "auto",
              }}
            >
              <Row style={{ margin: "0px", textAlign: "justify" }}>
                {" "}
                We never see resumes and degrees any papers. Kindly send us a
                mail with your name, number and your story and our team will
                respond to you.
              </Row>
              <br />
              <div style={{ textAlign: "center" }}>
                <a href="https://mail.google.com">
                  balasubramaniyan@travailtechnologies.in
                </a>
              </div>
            </ModalBody>
            <br />
            <ModalFooter>
              <Button
                className="career-button-apply"
                style={{ width: "30%" }}
                //   style={{
                //     border: "1px solid #268da5",
                //     color: "#268da5",
                //     backgroundColor: "#fff",
                //   }}
                onClick={this.toggle}
              >
                OK
              </Button>
            </ModalFooter>
          </Modal>
        )} */}
      </div>
      <div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default CareerDetailsTesting;
